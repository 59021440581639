import { FieldError, useFormContext } from "react-hook-form";

import { RHFInput } from "components/input/RHFInput";
import { useTranslation } from "hooks/use-translate";
import { isValidEmail } from "libs/is-valid-email";
import { isValidOrganizationNumberOrSSN } from "libs/is-valid-organization-number-or-ssn";
import { isValidPhone } from "libs/is-valid-phone";
import { isValidSwedishZipcode } from "libs/is-valid-zipcode";
import { getDigitsFromString } from "libs/number-format";
import { Deal } from "models/deals/deal";
import { DealInputProps } from "./models/field-types";
import { useUpdateDealProperty } from "./useUpdateDealProperty";

function DealInput({
  disabled = false,
  configuration,
  type = "text",
}: DealInputProps) {
  const { isLoading } = useUpdateDealProperty();
  const { translate } = useTranslation();

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<Partial<Deal>>();

  const error = errors[configuration.property] as FieldError;
  const MAX_NUM_VALUE = Number.MAX_SAFE_INTEGER;

  const dealInputOptions = register(configuration.property, {
    required: translate("FIELD_REQUIRED"),
    validate: {
      isValid: (value) => {
        const stringValue = value as string;
        switch (configuration.property) {
          case "contactEmail":
            return isValidEmail(stringValue) || translate("INVALID_EMAIL");
          case "organisationsnummer":
            if (stringValue.length > 10) {
              return translate("INVALID_SSN");
            }
            return (
              isValidOrganizationNumberOrSSN(stringValue, true) ||
              translate("INVALID_SSN")
            );
          case "contactPhoneNumber":
            return isValidPhone(stringValue) || translate("INVALID_PHONE");
          case "postnummer": {
            return (
              isValidSwedishZipcode(getDigitsFromString(stringValue)) ||
              translate("POSTAL_CODE_INVALID")
            );
          }
          default:
            return true;
        }
      },
    },
  });
  const handleOnChange = (value: string) => {
    if (type === "number") {
      if (parseInt(value, 10) > MAX_NUM_VALUE) {
        setValue(configuration.property, getValues(configuration.property), {
          shouldValidate: true,
        });
        return;
      }
      setValue(configuration.property, value, {
        shouldValidate: true,
      });
    }
    if (
      configuration.property === "organisationsnummer" ||
      configuration.property === "contactPhoneNumber"
    ) {
      setValue(configuration.property, getDigitsFromString(value), {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue(configuration.property, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  return (
    <RHFInput
      className="deal-input"
      type={type}
      data-testid={`deal-${configuration.property}`}
      disabled={isLoading || disabled}
      register={dealInputOptions}
      placeholder={configuration.placeholder}
      errorMessage={error?.message}
      onChange={({ target }) => {
        handleOnChange(target.value);
      }}
      onBlur={(e) => {
        e.preventDefault();
      }}
    />
  );
}
export default DealInput;
