import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import Bugsnag from "@bugsnag/js";
import { Avatar } from "@fluentui/react-components";

import { authMethod } from "auth";
import AppConfig from "app-config";
import { AppRouteHelper, CustomerDetailsParams } from "routes";
import { CustomersAPI } from "api/customers";
import { useAuth } from "auth/use-auth";
import { Customer } from "models/customer";
import { Assignment } from "models/assignment";
import { useSorting } from "hooks/use-sort-column";
import { useTranslation } from "hooks/use-translate";
import { isDateValid } from "libs/date/valid-date";
import BreadcrumbV9, {
  BreadcrumbDefinitionItem,
} from "components/breadcrumbV2";
import { ShimmerCell, ShimmerCircle } from "components/shimmer";
import { LayoutHeader, LayoutWrapper } from "components/layout/Layout";
import { SortIcon } from "components/icon";
import StatusIndicatorIcon from "components/icon/StatusIndicatorIcon";
import { Content, ContentBody } from "components/content/Content";
import { ContentHeader } from "components/content/ContentHeader";
import { Persona } from "components/people";

const MACONOMY_URL = `${AppConfig.MACONOMY_URL}`;

const SortAssignmentNumber = (a: Assignment) => a.project_number;
const SortProjectManagerEmail = (a: Assignment) => a.project_manager_email;
const SortLastInvoiceDate = (a: Assignment) => a.last_invoice_date;
const SortActive = (a: Assignment) => a.closed;

export default function CustomerDetails() {
  const [assignments, setProjects] = useState<Assignment[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { customerId } = useParams<CustomerDetailsParams>();
  const [customer, setCustomer] = useState<Customer>();
  const navigate = useNavigate();
  const { sorting, toggleSortSelector } = useSorting<Assignment>(
    (t) => t.project_number
  );
  const { translate } = useTranslation();
  const { user } = useAuth();

  const URLProps = new URLSearchParams(window.location.search);
  const isConveyorBeltV2 = URLProps.get("conveyorBeltV2");

  const fetchCustomer = useCallback(async () => {
    if (!customerId) {
      return;
    }

    if (customer) {
      return;
    }

    setIsLoading(true);
    try {
      const token = await authMethod.getStoredAccessToken();
      const customerResponse = await CustomersAPI.fetchCustomerById(
        token,
        customerId
      );
      const assignmentsResponse = await CustomersAPI.fetchCustomerAssignments(
        token,
        customerId
      );
      setCustomer(customerResponse);
      setProjects(assignmentsResponse);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }

    setIsLoading(false);
  }, [customer, customerId]);

  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer]);

  const openChatWithAssignmentManager = (
    selectedCustomer: Customer,
    assignment: Assignment
  ) => {
    const topic = `${selectedCustomer.name} kundchatt`;
    const url = `https://teams.microsoft.com/l/chat/1/0?users=${
      assignment.project_manager
    },${AppConfig.LUDVIG_INTERNAL_CHAT_USER}&topicName=${encodeURI(topic)}`;
    microsoftTeams.executeDeepLink(url);
  };

  const breadcrumbs: BreadcrumbDefinitionItem[] = [
    {
      text: translate("MY_ASSIGNMENTS"),
      onClick: () =>
        navigate(
          isConveyorBeltV2
            ? AppRouteHelper.getAssignments("", true)
            : AppRouteHelper.getAssignments()
        ),
    },
    {
      text: customer ? customer.name : "...",
      onClick: () =>
        customer &&
        navigate(
          isConveyorBeltV2
            ? AppRouteHelper.getAssignments(customer.customer_number, true)
            : AppRouteHelper.getAssignments(customer.customer_number)
        ),
    },
    {
      text: translate("CUSTOMER_DETAILS.PROJECTS"),
    },
  ];

  const getProjectManagerAvatar = (projectManagerFullName: string) => {
    const projectManagerName = projectManagerFullName.split(", ");
    const projectManager = `${projectManagerName[1]} ${projectManagerName[0]}`;

    return (
      <span>
        <Avatar
          aria-label={projectManager}
          name={projectManager}
          className="mr-sm"
        />
        {projectManager}
      </span>
    );
  };

  const renderShimmerTableBody = () => {
    return [1, 0.5, 0.25, 0.125].map((opacity) => (
      <tr key={opacity} style={{ opacity }}>
        <td>
          <ShimmerCell />
        </td>
        <td>
          <ShimmerCell />
        </td>
        <td>
          <ShimmerCell />
        </td>
        <td>
          <ShimmerCell />
        </td>
        <td>
          <ShimmerCell />
        </td>
        <td className="text-center">
          <ShimmerCircle />
        </td>
      </tr>
    ));
  };

  if (!customerId) {
    return <>Missing customer route param ID</>;
  }

  return (
    <>
      <LayoutHeader>
        <BreadcrumbV9 items={breadcrumbs} />
      </LayoutHeader>
      <LayoutWrapper>
        <Content>
          <ContentHeader
            title={translate("CUSTOMER_DETAILS.PROJECTS")}
            counter={assignments.length}
          />
          <ContentBody>
            <table className="sticky hover-effect">
              <thead>
                <tr>
                  <th onClick={() => toggleSortSelector(SortAssignmentNumber)}>
                    {translate("CUSTOMER_DETAILS.PROJECT_NUMBER")}
                    <SortIcon
                      descending={sorting.isDescendingOrder}
                      show={sorting.selector === SortAssignmentNumber}
                    />
                  </th>
                  <th
                    onClick={() => toggleSortSelector(SortProjectManagerEmail)}
                  >
                    {translate("CUSTOMER_DETAILS.PROJECT_MANAGER")}
                    <SortIcon
                      descending={sorting.isDescendingOrder}
                      show={sorting.selector === SortProjectManagerEmail}
                    />
                  </th>
                  <th onClick={() => toggleSortSelector(SortLastInvoiceDate)}>
                    {translate("CUSTOMER_DETAILS.LAST_INVOICED")}
                    <SortIcon
                      descending={sorting.isDescendingOrder}
                      show={sorting.selector === SortLastInvoiceDate}
                    />
                  </th>
                  <th
                    onClick={() => toggleSortSelector(SortActive)}
                    className="text-center"
                  >
                    {translate("STATUS")}
                    <SortIcon
                      descending={sorting.isDescendingOrder}
                      show={sorting.selector === SortActive}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {customer &&
                  assignments &&
                  assignments.sort(sorting.sort).map((a) => {
                    const maconomyLink = `${MACONOMY_URL}/workspace/jobhome;JobNumber=${a.project_number}`;

                    return (
                      <tr key={a.project_number}>
                        <td>
                          <a
                            href={maconomyLink}
                            rel="noreferrer"
                            target="_blank"
                            className="color-listLinkColor text-decoration-none"
                          >
                            {a.project_number}
                          </a>
                        </td>
                        <td>
                          {user?.email !== a.project_manager_email ? (
                            <div
                              className="cursor-pointer"
                              onClick={() =>
                                openChatWithAssignmentManager(customer, a)
                              }
                            >
                              {a.project_manager_email ? (
                                <Persona userId={a.project_manager_email} />
                              ) : (
                                getProjectManagerAvatar(a.project_manager_name)
                              )}
                            </div>
                          ) : (
                            getProjectManagerAvatar(a.project_manager_name)
                          )}
                        </td>
                        <td>
                          {isDateValid(a.last_invoice_date)
                            ? a.last_invoice_date.toLocaleDateString("sv")
                            : "NULL"}
                        </td>
                        <td className="text-center">
                          <StatusIndicatorIcon
                            status={!a.closed ? "active" : "inactive"}
                          />
                        </td>
                      </tr>
                    );
                  })}
                {isLoading && renderShimmerTableBody()}
              </tbody>
            </table>

            {!isLoading && !customer && (
              <p className="p-3">
                {translate("CUSTOMER_DETAILS.CHARGE_CUSTOMER_ERROR")}
              </p>
            )}

            {!isLoading && customer && assignments.length === 0 && (
              <p className="p-3">
                {translate("CUSTOMER_DETAILS.NO_ASSIGNMENTS")}
              </p>
            )}
          </ContentBody>
        </Content>
      </LayoutWrapper>
    </>
  );
}
