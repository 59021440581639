import { useTranslation } from "hooks/use-translate";
import { PersonPicker } from "components/people";

export type Assignee = {
  email?: string;
  name?: string;
  service_type: string;
  isDefault: boolean;
};

type Props = {
  packageName: string;
  defaultAssignee?: string;
  isLoading?: boolean;
  onPackagePersonPickerChange: (
    currPackage: string,
    personEmail: string
  ) => void;
};

export default function AssignedUserForPackage({
  packageName,
  onPackagePersonPickerChange,
  defaultAssignee,
  isLoading = false,
}: Props) {
  const { translate } = useTranslation();

  return (
    <PersonPicker
      className="me-3"
      selectionMode="single"
      placeholder={translate("SEARCH_PERSON")}
      isLoading={isLoading}
      emptyPlaceholder={
        defaultAssignee
          ? translate("CHANGE_DEFAULT_EMPLOYEE")
          : translate("ASSIGN_DEFAULT_EMPLOYEE")
      }
      selectedUsers={defaultAssignee ? [{ id: defaultAssignee, name: "" }] : []}
      onPersonChange={(_, __, person) =>
        person &&
        person.userPrincipalName &&
        onPackagePersonPickerChange(packageName, person.userPrincipalName)
      }
    />
  );
}
