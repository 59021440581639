import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { RootState } from "state";
import {
  fetchCustomerCallerWrapper,
  fetchCustomerSettingsDataWrapper,
} from "state/customerSettings/customerSettingsThunk";

import AppConfig from "app-config";
import { AccordionV2 } from "components/accordionV2";
import { ContentV2 } from "components/contentV2/ContentV2";
import { useTranslation } from "hooks/use-translate";
import { CompanyInformation } from "models/offer/Company";
import { AppRouteHelper, CustomerDetailsParams } from "routes";
import {
  fetchBalanceInvoicePaymentsData,
  fetchPGUData,
} from "state/activities/actions";
import { appendToastMessage } from "state/notifications";
import { SearchBy, fetchCompanyInfoFromSales } from "state/offer/companyThunks";
import { AppDispatch } from "state/use-app-redux";
import CompanyNotFound from "../CompanyNotFound";
import CustomerPageSection from "../components/customerPageSection";
import BalancesTable from "../customerPageBalances/balancesTable";
import CustomerPageBasicInfo from "../customerPageBasicInfo/customerPageBasicInfo";
import CustomerPageCompanyInfo from "../customerPageCompanyInfo";
import CustomerPageContacts from "../customerPageContacts/customerPageContacts";
import CustomerPageContracts from "../customerPageContracts";
import CustomerPageDetailedInfo from "../customerPageDetailedInfo/customerPageDetailedInfo";
import CustomerPageEnabledSettings from "../customerPageEnabledSettings/customerPageEnabledSettings";
import YearlyEvaluationKYCFormLatest from "../customerPageKYCYearlyEvaluation/YearlyEvaluationKYCFormLatest";
import CustomerPagePowerOfAttorney from "../customerPagePowerOfAttorney/customerPagePowerOfAttorney";
import CustomerPageServiceNotes from "../customerPageServiceNotes/customerPageServiceNotes";
import CustomerPageTaxObjects from "../customerPageTaxObjects";
import "../index.scss";
import PguMainTable from "../tables/pguMainTable";

export default function CustomerMainPage() {
  const dispatch: AppDispatch = useDispatch();
  const { translate } = useTranslation();

  const MAX_ELEMENTS = 6;

  const visibleCustomerSettings = AppConfig.FEATURES.VISIBLE_CUSTOMER_SETTINGS;

  const [companyInfo, setCompanyInfo] = useState<CompanyInformation>();
  const [customerData, setCustomerData] = useState<any>();
  const [pguData, setPguData] = useState<any>();
  const [customerNotFound, setCustomerNotFound] = useState(false);

  const { customerId } = useParams<CustomerDetailsParams>();

  const { currentUser } = useSelector((state: RootState) => state.users);
  const customers = useSelector((state: RootState) => state.customers.data);
  const {
    contacts,
    powerOfAttorneyData,
    info,
    serviceNotes,
    specificInfoLoadingStatus,
    serviceNotesLoadingStatus,
    powerOfAttorneyLoadingStatus,
    serviceLine,
    kycBatchLoadingStatus,
    customerSettingsStatus,
    expandAll,
  } = useSelector((state: RootState) => ({
    contacts: state.settings.contacts.data,
    powerOfAttorneyData: state.settings.powerOfAttorney.data,
    info: state.settings.detailedInfo.data.info,
    serviceNotes: state.settings.serviceNotes.data,
    specificInfoLoadingStatus: state.settings.detailedInfo.status,
    serviceNotesLoadingStatus: state.settings.serviceNotes.status,
    powerOfAttorneyLoadingStatus: state.settings.powerOfAttorney.status,
    serviceLine: state.settings.serviceLines.currentServiceLine,
    kycBatchLoadingStatus: state.settings.formKYCAnswersBatch.status,
    customerSettingsStatus: state.settings.customerSettings.status,
    expandAll: state.settings.expandAll,
  }));

  const customer = customers.find(
    (e) => e.customer.customer_number === customerId
  )?.customer;

  const activePageObjects = customerData?.slice(0, MAX_ELEMENTS);

  const PGU_AND_BALANCE_INVOICES_ENABLED =
    !serviceLine || serviceLine?.serviceline === "General";

  useEffect(
    () => {
      const init = async () => {
        if (customerId) {
          dispatch(fetchCustomerCallerWrapper(customerId));
          try {
            const company = await dispatch(
              fetchCompanyInfoFromSales({
                searchByParam: SearchBy.CustomerNumber,
                customerId,
              })
            ).unwrap();

            if (customer && company && company.org_number) {
              setCompanyInfo(company);

              dispatch(
                fetchCustomerSettingsDataWrapper({
                  customer,
                  userGraphId: currentUser.graphId,
                })
              );

              if (customer && PGU_AND_BALANCE_INVOICES_ENABLED) {
                dispatch(fetchBalanceInvoicePaymentsData(customer))
                  .then((res) => setCustomerData(res))
                  .catch((err) =>
                    dispatch(
                      appendToastMessage("SOMETHING_WENT_WRONG", "error")
                    )
                  );
                dispatch(fetchPGUData(customer))
                  .then((res) => setPguData(res))
                  .catch((err) =>
                    dispatch(
                      appendToastMessage(
                        "CUSTOMER_PAGE.PGU_FETCH_ERROR",
                        "error"
                      )
                    )
                  );
              }

              if (!company.primary_contact_id) {
                dispatch(
                  appendToastMessage(
                    "CUSTOMER_PAGE.NO_PRIMARY_CONTACT_WARNING",
                    "warning",
                    [],
                    translate("GO_TO_CONTACTS"),
                    AppRouteHelper.getCustomerPageContactsPage(customerId, true)
                  )
                );
              }
            }
          } catch (err) {
            setCustomerNotFound(true);
          }
        }
      };
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customer,
      customerId,
      dispatch,
      PGU_AND_BALANCE_INVOICES_ENABLED,
      currentUser.graphId,
    ]
  );

  return (
    <>
      {customerNotFound && <CompanyNotFound />}
      {customer && companyInfo && customerId && (
        <ContentV2>
          <AccordionV2
            title={translate("COMPANY_DETAILS")}
            isInitiallyOpen
            expanded={expandAll}
          >
            <CustomerPageCompanyInfo
              companyInfo={companyInfo}
              customer={customer}
            />
          </AccordionV2>
          <Row className="horizontal-divider" />

          <div className="contact-cards">
            <AccordionV2
              title={translate("CONTACTS")}
              isInitiallyOpen
              subtitle={`(${contacts.length})`}
              expanded={expandAll}
            >
              <Row>
                <Col>
                  <Col xl={visibleCustomerSettings.basicinfo ? 6 : 12} lg={12}>
                    {customerId && customer.company_registration_number && (
                      <CustomerPageContacts customerId={customerId} />
                    )}
                  </Col>

                  {visibleCustomerSettings.basicinfo && (
                    <Col xl={6} lg={12}>
                      <CustomerPageSection sectionTitle={translate("SERVICES")}>
                        <CustomerPageBasicInfo customerId={customerId} />
                      </CustomerPageSection>
                    </Col>
                  )}
                </Col>
              </Row>
            </AccordionV2>
          </div>
          <Row className="horizontal-divider" />

          {PGU_AND_BALANCE_INVOICES_ENABLED && (
            <>
              <AccordionV2
                title={translate("CUSTOMER_PAGE_PGU")}
                expanded={expandAll}
                subtitle={`(${pguData?.length})`}
                loadingStatus={pguData}
              >
                {pguData && (
                  <PguMainTable
                    activePageObjects={pguData.slice(0, MAX_ELEMENTS)}
                    customerNumber={customer?.customer_number}
                    isNotDetailedPage
                  />
                )}
              </AccordionV2>
              <Row className="horizontal-divider" />
            </>
          )}

          {PGU_AND_BALANCE_INVOICES_ENABLED && (
            <>
              <AccordionV2
                title={translate("CUSTOMER_PAGE_BALANCES_INVOICES_PAYMENTS")}
                expanded={expandAll}
                subtitle={`(${customerData?.length})`}
                loadingStatus={customerData}
              >
                {customerData && (
                  <BalancesTable
                    activePageObjects={activePageObjects}
                    customerNumber={customer.customer_number}
                    isNotDetailedPage
                  />
                )}
              </AccordionV2>
              <Row className="horizontal-divider" />
            </>
          )}

          <>
            <AccordionV2
              title={translate("CUSTOMER_SPECIFIC_INFORMATION")}
              subtitle={`(${info.length})`}
              expanded={expandAll}
              loadingStatus={specificInfoLoadingStatus}
              infoTooltip={translate("CUSTOMER_SPECIFIC_INFO_TOOLTIP")}
            >
              <CustomerPageDetailedInfo
                customerId={customerId}
                customerManagerId={customer.salesperson_number}
              />
            </AccordionV2>
            <Row className="horizontal-divider" />
          </>

          <>
            <AccordionV2
              title={translate("SERVICE_NOTES")}
              expanded={expandAll}
              subtitle={`(${serviceNotes.length})`}
              loadingStatus={serviceNotesLoadingStatus}
              infoTooltip={translate("SERVICE_NOTES_TOOLTIP")}
            >
              <CustomerPageServiceNotes
                customerId={customerId}
                customerManagerId={customer.salesperson_number}
              />
            </AccordionV2>
            <Row className="horizontal-divider" />
          </>

          <AccordionV2
            title={translate("CUSTOMER_SETTINGS_OVERVIEW")}
            expanded={expandAll}
            loadingStatus={customerSettingsStatus}
            infoTooltip={translate("SETTINGS_TOOLTIP")}
          >
            <CustomerPageEnabledSettings customerId={customerId} />
          </AccordionV2>

          <>
            <AccordionV2
              title={translate("YEARLY_EVALUATION_KYC")}
              expanded={expandAll}
              loadingStatus={kycBatchLoadingStatus}
            >
              <YearlyEvaluationKYCFormLatest customerId={customerId} />
            </AccordionV2>
            <Row className="horizontal-divider" />
          </>

          <>
            <AccordionV2
              title={translate("POWER_OF_ATTORNEY")}
              subtitle={`(${powerOfAttorneyData.length})`}
              expanded={expandAll}
              loadingStatus={powerOfAttorneyLoadingStatus}
            >
              <CustomerPagePowerOfAttorney customerId={customerId} />
            </AccordionV2>
            <Row className="horizontal-divider" />
          </>

          {visibleCustomerSettings.taxobjects && (
            <>
              <AccordionV2
                title={translate("TAX_OBJECT_LABEL")}
                expanded={expandAll}
              >
                <CustomerPageTaxObjects />
              </AccordionV2>
              <Row className="horizontal-divider" />
            </>
          )}

          <CustomerPageContracts customerId={customerId} expanded={expandAll} />
        </ContentV2>
      )}
    </>
  );
}
