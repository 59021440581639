import { Person, ViewType } from "@microsoft/mgt-react";
import type { PersonProps, MgtTemplateProps } from "@microsoft/mgt-react";
import { PersonWarning20Regular } from "@fluentui/react-icons";
import { makeStyles, mergeClasses } from "@fluentui/react-components";
import { useTranslation } from "hooks";

const useStyles = makeStyles({
  notFound: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

type PersonaProps = {
  className?: string;
  avatarType?: "photo";
} & PersonProps;

type PersonNotFoundType = {
  className?: string;
  label?: string;
} & MgtTemplateProps;

function PersonNotFoundTemplate(props: PersonNotFoundType) {
  const styles = useStyles();
  const { className, label } = props;

  return (
    <div
      {...props}
      className={`persona-not-active ${mergeClasses(
        styles.notFound,
        className
      )}`}
    >
      <PersonWarning20Regular className="not-active-icon" />
      <span className="body-italic-light not-active-placeholder">{label}</span>
    </div>
  );
}

function PersonNotFoundImageOnlyTemplate(props: PersonNotFoundType) {
  const styles = useStyles();
  const { className } = props;

  return (
    <div {...props} className={mergeClasses(styles.notFound, className)}>
      <PersonWarning20Regular />
    </div>
  );
}

export function Persona({
  avatarType = "photo",
  avatarSize = "small",
  className,
  showPresence = false,
  personDetails,
  view = ViewType.image,
  userId,
  line2Property = "jobTitle",
  ...rest
}: PersonaProps) {
  const { translate } = useTranslation();

  const renderNotFoundTemplate = (label: string) => {
    return view === ViewType.image ? (
      <PersonNotFoundImageOnlyTemplate template="no-data" />
    ) : (
      <PersonNotFoundTemplate template="no-data" label={label} />
    );
  };

  return (
    <div className={`persona ${className}`}>
      <Person
        className="person"
        avatarSize={avatarSize}
        showPresence={showPresence}
        userId={userId}
        fetchImage
        avatarType={avatarType}
        view={view}
        line2Property={line2Property}
        {...rest}
      >
        {renderNotFoundTemplate(translate("MGT_PERSONA_EMPLOYEE_NOT_ACTIVE"))}
      </Person>
    </div>
  );
}
