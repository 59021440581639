import {
  Add20Regular,
  ArrowSync20Regular,
  ArrowUpRight20Regular,
  Bookmark20Regular,
  ClipboardTextLtr20Regular,
  DataPie20Regular,
  DocumentAdd20Regular,
  DocumentText20Regular,
  FolderLink20Regular,
  Info20Regular,
  Link20Regular,
  Payment20Regular,
  PeopleTeam20Regular,
  Search20Regular,
  Settings20Regular,
  Warning20Filled,
  //  PersonAdd20Regular,
} from "@fluentui/react-icons";
import React, { useContext, useState } from "react";
import { Spinner } from "@fluentui/react-components";

import AppConfig from "app-config";
import { Button } from "components/button";
import ContextualMenuV9 from "components/contextualMenu/ContextualMenuV9";
import { NotificationIcon } from "components/icon";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { Persona } from "components/people";
import { OverlaySpinner, SpinnerSize } from "components/spinner";
import TooltipV9 from "components/tooltip/TooltipV9";
import { fetchDataForNewBusinessOpportunity } from "helpers/BusinessOpportinityHelper";
import { useTranslation } from "hooks/use-translate";
import {
  //  isAllowedToGetTemporaryAccess,
  isUserTeamOwner,
} from "libs/customer-settings-helpers";
import { renderIcon } from "libs/render-icon";
import {
  ActivityInstance,
  Recurrence,
  ServiceTypes,
} from "models/activities/activity";
import { Customer } from "models/customer";
import { CustomerPageDetailedPageEnum } from "models/enums/CustomerPageDetailedPage.enum";
import { User, UserRoles } from "models/user";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import { updatePackageDefaultAssignedUser } from "state/activities/actions";
import { syncCustomerActivities } from "state/activities/syncActivities.state";
import { setOpenCreateModalFor } from "state/customerSettings/customerSettingsSlice";
import { fetchServiceLinesCallerWrapper } from "state/customerSettings/customerSettingsThunk";
import {
  createCustomerTeam,
  fetchTeamsFilesTabLink,
} from "state/customers/actions";
import { appendToastMessage } from "state/notifications";
import { useAppDispatch, useAppSelector } from "state/use-app-redux";
import { useAssignees } from "views/activities/components/assignees/useAssignees";
import CustomerPageServiceNotesModal from "views/activities/customerPage/customerPageServiceNotes/customerPageServiceNotesModal";
import { CreateBusinessOpportunityContext } from "views/bussinessOpportunity/wizard/CreateBusinessOpportinityContext";
import "../SortAndFilter.scss";
import { CapegoFortnoxConnectionModal } from "../modals/CapegoFortnoxConnectionModal";
import CustomerOverviewReportsLink from "../modals/CustomerOverviewReportsLink";
import { PostponePaymentForInvoiceModal } from "../modals/PostponePaymentForInvoiceModal";
import ReportsModal from "../modals/ReportsModal";
import { SIEFilesModal } from "../modals/SIEFilesModal";
import TeamModal from "./TeamModal";
// import { TemporaryAccessModal } from "../modals/TemporaryAccessModal";

const MACONOMY_URL = `${AppConfig.MACONOMY_URL}`;

const CBModalState = {
  TEAM_MODAL: "TEAM_MODAL",
  SERVICE_MODAL: "SERVICE_MODAL",
  CREATE_TEAM_MODAL: "CREATE_TEAM_MODAL",
  CAPEGO_FORNOX_MODAL: "CAPEGO_FORNOX_MODAL",
  SIE_FILES: "SIE_FILES",
  REPORTS: "REPORTS",
  DEFERRED_PAYMENT: "DEFERRED_PAYMENT",
  TEMPORARY_ACCESS: "TEMPORARY_ACCESS",
};

interface InitialState {
  modalState: string | null;
  isLoading: boolean;
  creatingTeamLoading: boolean;
}

const initialState: InitialState = {
  modalState: null,
  isLoading: false,
  creatingTeamLoading: false,
};

export type ActivitiesCustomerDetailsProps = React.HTMLProps<HTMLDivElement> & {
  customer: Customer;
  expanded: boolean;
  isCollapsedView?: boolean;
  isCollapsedRow?: boolean;
  toggleInfo: () => void;
  customerIsFromSearch: boolean;
};

export function ActivitiesCustomerDetails({
  customer,
  customerIsFromSearch,
  expanded,
  isCollapsedView = false,
  isCollapsedRow = false,
  toggleInfo,
}: ActivitiesCustomerDetailsProps) {
  const { translate } = useTranslation();
  const ServiceTypesList = Object.values(ServiceTypes);
  delete ServiceTypesList["2"]; // NOTE: temporary solution until we handle Income tax return service the proper way
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const hasAccessToSettings = isUserTeamOwner(customer, currentUser);

  const { resetContext } = useContext(CreateBusinessOpportunityContext);

  const activities = useSelector((state: RootState) => state.activities);
  const customerActivities = activities.data.find(
    (c) => c.customer_id === customer.customer_number
  );
  const isOnboardedInCB = !!(
    (customerActivities && customerActivities.active) ||
    customer.deliveryPlan.some((d) => d.is_locked_in)
  );
  const maconomyLink = `${MACONOMY_URL}/workspace/customers;CustomerNumber=${customer.customer_number}`;
  const customerState = useAppSelector((state) => state.customers).data.find(
    (c) => c.customer.customer_number === customer.customer_number
  );
  const [state, setState] = useState(initialState);

  const openModal = (modal: string) => {
    setState({
      ...initialState,
      modalState: modal,
    });
  };

  const closeModal = () => {
    setState(initialState);
  };

  // https://lrfkonsult.atlassian.net/browse/ML-932
  // Ticket is still in progress, BE fixes pending
  // uncomment when done
  // const shouldGetTemporaryAccess = isAllowedToGetTemporaryAccess(
  //   customer,
  //   currentUser
  // );

  function isIconApplicable(): boolean {
    if (customerState?.customer?.deliveryPlan !== undefined) {
      if (customerState.customer.deliveryPlan.length === 0) {
        return true;
      }
      const currentYear = new Date().getFullYear();
      const anyDeliveryLockedIn = customerState.customer.deliveryPlan.some(
        (d) => {
          if (d.start_date?.getFullYear() === currentYear && d.is_locked_in) {
            return true;
          }
          return false;
        }
      );
      return !anyDeliveryLockedIn;
    }
    return false;
  }

  const customerAssignedServices = customerActivities?.services.map(
    (s) => s.cb_service_type
  );

  const onPackagePersonPickerChange = async (
    packageName: string,
    userEmail: string
  ) => {
    const response = await dispatch(
      updatePackageDefaultAssignedUser(customer, packageName, userEmail)
    );

    return response;
  };

  const onClickAddServiceNote = () => {
    dispatch(fetchServiceLinesCallerWrapper());
    dispatch(setOpenCreateModalFor(CustomerPageDetailedPageEnum.SERVICE_NOTES));
    openModal(CBModalState.SERVICE_MODAL);
  };

  const assignees: {
    assigned_user: ActivityInstance;
    cb_service_type: string;
  }[] = useAssignees(
    ServiceTypesList,
    customerActivities ? customerActivities.services : [],
    customerActivities ? customerActivities.assigned_users : undefined
  );

  function removeDuplicateAssignees(
    arr: {
      assigned_user: ActivityInstance;
      cb_service_type: string;
    }[]
  ) {
    const unique = arr.filter(
      (v, i, a) =>
        a.findIndex(
          (v2) =>
            JSON.stringify(v2.assigned_user.assigned_user) ===
            JSON.stringify(v.assigned_user.assigned_user)
        ) === i
    );
    return unique;
  }

  const assigneesUnique = removeDuplicateAssignees(assignees);

  const customerProject = customer.projects.map((project) => {
    return {
      pmEmail: project.projectManagerEmail,
      projectType: project.projectType,
    };
  });

  function removeDuplicateEmailPMs(
    arr: { pmEmail: string; projectType: any }[]
  ) {
    const unique = arr.filter(
      (v, i, a) =>
        a.findIndex(
          (v2) => JSON.stringify(v2.pmEmail) === JSON.stringify(v.pmEmail)
        ) === i
    );
    return unique;
  }

  const projectManagersForCustomer = removeDuplicateEmailPMs(customerProject);

  if (!customerState) {
    return null;
  }

  function customerFrequencyReccurance(customerObj: Customer) {
    if (customerObj.frequency && customerObj.frequency !== Recurrence.Unknown) {
      const freq = customerObj.frequency.toLowerCase();
      return freq.charAt(0).toUpperCase() + freq.slice(1);
    }
  }

  const distinctAssigneeByEmailAndServiceType = (
    arr: {
      assigned_user: ActivityInstance;
      cb_service_type: string;
    }[]
  ) => {
    const map = new Map();
    for (const user of arr) {
      map.set(
        `${user.assigned_user.assigned_user} ${user.cb_service_type}`,
        user
      );
    }

    const distinctObjects: any = [];

    map.forEach((value, _) => {
      distinctObjects.push(value);
    });

    return distinctObjects;
  };

  const hasFiscalYear = customer.fiscalYearMonthEnd !== undefined;

  const emptyActivitiesReason = [
    {
      visible: !hasFiscalYear,
      message: translate("CUSTOMER_MISSING_FISCAL_YEARS"),
    },
    {
      visible: !customer.hasAcceptedTerms(),
      message: translate("CUSTOMER_HAS_NOT_ACCEPTED_GENERAL_TERMS"),
    },
  ];

  const hasWarning = emptyActivitiesReason.some((el) => el.visible);

  const enterNewDealFlowForCustomer = async () => {
    resetContext();

    setState({
      ...initialState,
      isLoading: true,
    });
    const customerData = await fetchDataForNewBusinessOpportunity(
      customer,
      dispatch,
      navigate
    );
    if (customerData?.status === "error") {
      dispatch(appendToastMessage("SOMETHING_WENT_WRONG", "error"));
    }
    setState({
      ...initialState,
      isLoading: false,
    });
  };

  const isDeferredPaymentAllowed = (current: User) => {
    if (current?.roles?.includes(UserRoles.FEATURES_DEFERRED_PAYMENT)) {
      return true;
    }

    return (
      current?.azureGroups?.some(
        (azureGroup) =>
          azureGroup.displayName === AppConfig.USER_ROLES.NYK_MANAGER
      ) || false
    );
  };

  const deferredPaymentAllowed = isDeferredPaymentAllowed(currentUser);

  const getCustomerDetailsMenuItems = () => {
    const settingsItems = hasAccessToSettings
      ? [
          {
            text: `${translate("CUSTOMER_SETTINGS")}`,
            onClick: () =>
              navigate({
                pathname: AppRouteHelper.getCustomerSettings(
                  customer.customer_number
                ),
              }),
            icon: () =>
              renderIcon(
                <div className="position-relative">
                  {isIconApplicable() && <NotificationIcon />}
                  <Settings20Regular
                    onClick={() =>
                      navigate({
                        pathname: AppRouteHelper.getCustomerSettings(
                          customer.customer_number
                        ),
                      })
                    }
                  />
                </div>
              ),
            hasDivider: true,
          },
        ]
      : [];

    const renderReportsLink = () => {
      if (customer.apps?.datalagret?.status === "offline") {
        return <CustomerOverviewReportsLink content="DATA_STORE_NO_RESPONSE" />;
      }

      if (!customer.customer_key) {
        return (
          <CustomerOverviewReportsLink content="MISSING_CUSTOMER_IN_DATALAGRET" />
        );
      }
      return (
        <CustomerOverviewReportsLink
          content="VIEW_DATALAGRET_REPORTS"
          onClick={() => openModal(CBModalState.REPORTS)}
        />
      );
    };

    const onGoToTeamsFolderClick = async () => {
      let url = customer.teamsFilesTabLink;
      if (!url) {
        url = await dispatch(fetchTeamsFilesTabLink(customer));
      }
      if (url) {
        window.open(url.replaceAll('"', ""), "_blank");
      }
    };

    const customerTeamsFolderLinkItems = customer.teamsStatus
      ? [
          {
            text: `${translate("GO_TO_TEAMS_FILES")}`,
            onClick: onGoToTeamsFolderClick,
            icon: () => renderIcon(<FolderLink20Regular />),
          },
        ]
      : [];

    const actionItems = [
      {
        title: translate("ACTIONS_SECTION"),
        className: "text-color-blue",
        groupItems: [
          {
            text: `${translate("NEW_CUSTOMER_BUSINESS_OPPORTUNITY")}`,
            onClick: enterNewDealFlowForCustomer,
            icon: () => renderIcon(<DocumentAdd20Regular />),
            hasDivider: true,
          },
          {
            text: `${translate("ADD_INDIVIDUAL_ACTIVITITES")}`,
            icon: () => renderIcon(<Add20Regular />),
            onClick: () =>
              navigate(`/assignments/${customer.customer_number}/edit`),
            hidden: !hasAccessToSettings,
            hasDivider: hasAccessToSettings,
          },
          {
            text: `${translate("ADD_NEW_SERVICE_NOTE")}`,
            icon: () => renderIcon(<Add20Regular />),
            onClick: () => {
              onClickAddServiceNote();
            },
            hidden: !hasAccessToSettings,
            hasDivider: hasAccessToSettings,
          },
          {
            text: `${translate("SYNC_CUSTOMER_ACTIVITIES")}`,
            onClick: () => {
              dispatch(syncCustomerActivities(customer.customer_number));
            },
            icon: () => renderIcon(<ArrowSync20Regular />),
            hidden: !hasAccessToSettings,
            hasDivider: hasAccessToSettings,
          },
          // https://lrfkonsult.atlassian.net/browse/ML-932
          // Ticket is still in progress, BE fixes pending
          // uncomment when done
          // {
          //   text: `${translate(
          //     "CUSTOMER_DETAILS_MENU.GET_TEMPORARY_ACCESS_TO_CUSTOMER"
          //   )}`,
          //   onClick: () => openModal(CBModalState.TEMPORARY_ACCESS),
          //   icon: () => renderIcon(<PersonAdd20Regular />),
          //   hidden: !shouldGetTemporaryAccess,
          //   hasDivider: shouldGetTemporaryAccess,
          // },
          {
            text: `${translate(
              customer.teamsStatus ? "SYNC" : "CREATE_NEW_TEAM"
            )}`,
            onClick: () => openModal(CBModalState.CREATE_TEAM_MODAL),
            icon: () => renderIcon(<PeopleTeam20Regular />),
            hasDivider: true,
          },
          {
            text: `${translate("CAPEGOFORTNOX_MANAGE_CONNECTION")}`,
            onClick: () => openModal(CBModalState.CAPEGO_FORNOX_MODAL),
            icon: () => renderIcon(<Link20Regular />),
            hasDivider: true,
          },
          {
            text: `${translate("POSTPONE_INVOICE_PAYMENT")}`,
            onClick: () => openModal(CBModalState.DEFERRED_PAYMENT),
            onRenderIcon: () => renderIcon(<Payment20Regular />),
            hidden: !deferredPaymentAllowed,
          },
        ],
      },
    ];

    const listItems = [
      {
        title: translate("LINKS_SECTION"),
        className: "text-color-blue",
        groupItems: [
          ...settingsItems,
          {
            text: `${translate("CUSTOMER_PAGE")}`,
            onClick: () =>
              navigate({
                pathname: AppRouteHelper.getCustomerPage(
                  customer.customer_number
                ),
              }),
            icon: () =>
              renderIcon(
                <div className="position-relative">
                  {isIconApplicable() && <NotificationIcon />}
                  <Bookmark20Regular
                    onClick={() =>
                      navigate({
                        pathname: AppRouteHelper.getCustomerSettings(
                          customer.customer_number
                        ),
                      })
                    }
                  />
                </div>
              ),
            hasDivider: true,
          },
          {
            text: `${translate("DELIVERY_PLANNER")}`,
            onClick: () =>
              navigate(
                AppRouteHelper.getCustomerDeliveryPlanner(
                  customer.customer_number
                )
              ),
            icon: () =>
              renderIcon(
                <div className="position-relative">
                  {isIconApplicable() && <NotificationIcon />}
                  <DataPie20Regular />
                </div>
              ),
            hasDivider: true,
          },
          {
            text: "Maconomy",
            onClick: () => {
              window.open(maconomyLink);
            },
            icon: () => renderIcon(<ArrowUpRight20Regular />),
            hasDivider: true,
          },
          {
            text: `${translate("CUSTOMER_DETAILS.PROJECTS")}`,
            onClick: () => {
              navigate({
                pathname: AppRouteHelper.getCustomerDetails(
                  customer.customer_number
                ),
              });
            },
            icon: () => renderIcon(<ClipboardTextLtr20Regular />),
            hasDivider: true,
          },
          {
            text: `${translate("CUSTOMER_ACTIONS.REPORTS")}`,
            onClick: () => openModal(CBModalState.REPORTS),
            onRenderIcon: () => renderReportsLink(),
            hasDivider: true,
          },
          {
            text: `${translate("CUSTOMER_ACTIONS.SIE_FILES")}`,
            onClick: () => openModal(CBModalState.SIE_FILES),
            onRenderIcon: () => renderIcon(<DocumentText20Regular />),
            hasDivider: true,
          },
          ...customerTeamsFolderLinkItems,
        ],
      },
      ...actionItems,
    ];

    return listItems;
  };

  const createOrSyncTeam = (selectedCustomer: Customer) => {
    setState({
      ...initialState,
      creatingTeamLoading: true,
    });
    dispatch(createCustomerTeam(selectedCustomer)).finally(() => {
      setState({
        ...initialState,
        creatingTeamLoading: false,
      });
      closeModal();
    });
  };

  return (
    <Row className="sticky top-align no-border min-width-1400 ">
      {state.isLoading && (
        <OverlaySpinner label={translate("NEW_DEAL_LOADING")} />
      )}
      <Col md={12}>
        <Row className="d-flex align-items-center">
          <Col xl={2} md={12} className="d-flex">
            {customerIsFromSearch && (
              <TooltipV9 content="CUSTOMER_IS_FROM_SEARCH">
                <div className="pr-xs">
                  <Search20Regular color="red" />
                </div>
              </TooltipV9>
            )}
            <TooltipV9 content={customer.name} notTranslatable>
              <div className="text-max-char pt-xs min-width-170 customer-details-name">
                <span className="align-items-center fpx-14 fw-semibold">
                  {customer.name}
                </span>
              </div>
            </TooltipV9>
            <div className="d-flex justify-content-center align-items-center">
              <div className="vertical-divider pr-sm ml-sm" />
            </div>
            {hasWarning && (
              <div>
                <TooltipV9
                  hasJSXContent
                  content={
                    <div className="flex-column-tooltip-content align-items-start">
                      {emptyActivitiesReason.map((el, index) => (
                        <span key={index}>
                          {el.visible && <li> {el.message} </li>}
                        </span>
                      ))}
                    </div>
                  }
                >
                  <Warning20Filled className="color-red mt-xs" />
                </TooltipV9>
              </div>
            )}
            <TooltipV9 content="VIEW_CLIENT_INFO">
              <div
                className="d-flex justify-content-center align-items-center position-relative"
                onClick={() => toggleInfo()}
              >
                <Info20Regular className="cursor-pointer transform-y-5" />
              </div>
            </TooltipV9>

            {customerState.isLoading && (
              <Spinner size={SpinnerSize.ExtraSmall} />
            )}

            <TooltipV9 content="MORE_OPTIONS">
              <ContextualMenuV9 menuGroups={getCustomerDetailsMenuItems()} />
            </TooltipV9>
          </Col>
        </Row>
        {!isCollapsedRow && (
          <>
            <Row className="d-flex align-items-center">
              <Col xl={2} md={12} className="d-flex mb-2">
                <div className="d-flex">
                  <span>{translate("FISCAL_YEAR")}</span>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="vertical-divider pr-sm ml-sm" />
                  </div>
                  <span className="fw-semibold">
                    {customer.fiscalYearMonthEnd
                      ? `${customer.fiscalYearMonthEnd}-${customer.fiscalYearDayEnd}`
                      : translate("MISSING_FISCAL_YEAR")}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="d-flex align-items-center">
              <Col xl={2} md={12} className="d-flex">
                <div className="d-flex">
                  <span>{translate("FREQUENCY")}</span>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="vertical-divider pr-sm ml-sm" />
                  </div>
                  <span className="fw-semibold">
                    {customerFrequencyReccurance(customer)
                      ? customerFrequencyReccurance(customer)
                      : " "}
                  </span>
                </div>
              </Col>
            </Row>
          </>
        )}
        <Row className="d-flex align-items-center">
          <Col xl={2} md={12} className="d-flex">
            {(expanded || (isCollapsedView && !isCollapsedRow)) && (
              <TooltipV9 content="TEAM_MODAL">
                <div
                  onClick={() =>
                    state.modalState !== CBModalState.TEAM_MODAL
                      ? openModal(CBModalState.TEAM_MODAL)
                      : closeModal()
                  }
                  className="d-flex cursor-pointer justify-content-center align-items-center py-xs"
                >
                  <div className="text-wrap-none">
                    <span>{translate("PROJECT_MANAGERS")}</span>
                  </div>
                  <div className="vertical-divider pr-sm ml-sm" />
                  <div>
                    <div
                      style={{ maxWidth: "120px" }}
                      className="d-flex align-items-center text-wrap-none"
                    >
                      {projectManagersForCustomer.length !== 0 ? (
                        <>
                          {projectManagersForCustomer.map(
                            (proj, index) =>
                              index < 3 && (
                                <div key={proj.pmEmail}>
                                  <Persona
                                    userId={proj.pmEmail}
                                    className="me-1"
                                  />
                                </div>
                              )
                          )}
                          {projectManagersForCustomer.length > 3 && (
                            <Button
                              variant="outline-outline"
                              className="mx-0 px-0 text-primary"
                              size="md"
                            >
                              <span className="mx-0 px-0 text-primary fpx-12">
                                +{projectManagersForCustomer.length - 3}
                              </span>
                            </Button>
                          )}
                        </>
                      ) : (
                        <span className="text-gray fs-italic">
                          No project manager(s)
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </TooltipV9>
            )}
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xl={2} md={12} className="d-flex">
            {(expanded || (isCollapsedView && !isCollapsedRow)) && (
              <TooltipV9 content="TEAM_MODAL">
                <div
                  onClick={() =>
                    state.modalState !== CBModalState.TEAM_MODAL
                      ? openModal(CBModalState.TEAM_MODAL)
                      : closeModal()
                  }
                  className="d-flex cursor-pointer justify-content-center align-items-center py-xs"
                >
                  <div className="text-wrap-none">
                    <span>{translate("ASSIGNEES")}</span>
                  </div>
                  <div className="vertical-divider pr-sm ml-sm" />
                  <div className="d-flex">
                    <div className="d-flex align-items-center justify-content-end">
                      {isOnboardedInCB && assigneesUnique.length > 0 ? (
                        <>
                          {assigneesUnique.map((a, index) => {
                            return (
                              index < 3 && (
                                <Persona
                                  className="mx-1"
                                  userId={a.assigned_user.assigned_user}
                                />
                              )
                            );
                          })}
                          {assigneesUnique.length > 3 && (
                            <Button
                              variant="outline-outline"
                              className="mx-0 px-0 text-primary"
                              size="md"
                            >
                              <span className="mx-0 px-0 text-primary fpx-12">
                                +{assigneesUnique.length - 3}
                              </span>
                            </Button>
                          )}
                        </>
                      ) : (
                        <span className="text-gray fs-italic">
                          No asignee(s)
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </TooltipV9>
            )}

            {state.modalState === CBModalState.TEAM_MODAL && (
              <TeamModal
                customer={customer}
                projectManagers={projectManagersForCustomer}
                assignees={distinctAssigneeByEmailAndServiceType(assignees)}
                onPackagePersonPickerChange={onPackagePersonPickerChange}
                customerAssignedServices={
                  customerAssignedServices
                    ? ServiceTypesList.filter((value) =>
                        customerAssignedServices.includes(value)
                      )
                    : []
                }
                isOpen={state.modalState === CBModalState.TEAM_MODAL}
                onDismiss={(isOpen: boolean) =>
                  isOpen ? openModal(CBModalState.TEAM_MODAL) : closeModal()
                }
              />
            )}
          </Col>
        </Row>
        {state.modalState === CBModalState.SERVICE_MODAL && (
          <CustomerPageServiceNotesModal
            customerId={customer.customer_number}
            openModal={state.modalState === CBModalState.SERVICE_MODAL}
            setOpenModal={(isOpen: boolean) =>
              isOpen ? openModal(CBModalState.SERVICE_MODAL) : closeModal()
            }
            createdFromCB
          />
        )}
        <Modal
          size="medium"
          isOpen={state.modalState === CBModalState.CREATE_TEAM_MODAL}
          onDismiss={() => !state.creatingTeamLoading && closeModal()}
          header={
            <ModalHeader
              headerTitleText={
                customer?.teamsStatus ? "SYNC" : "CREATE_NEW_TEAM"
              }
            />
          }
          footer={
            <ModalFooter
              labelCancel="CANCEL"
              labelSubmit={customer?.teamsStatus ? "SYNC" : "CREATE_NEW_TEAM"}
              onCancel={closeModal}
              onSave={() => createOrSyncTeam(customer)}
              isDisabled={state.creatingTeamLoading}
              isLoading={state.creatingTeamLoading}
            />
          }
        >
          {!customer?.teamsStatus
            ? `${translate("A_TEAM_FOR_CUSTOMER")} "${
                customer?.name
              }" ${translate("TEAM_NOT_EXIST_CREATE")}`
            : `${translate("TEAMS_EXISTS_FOR_CUSTOMER")} "${
                customer?.name
              }". ${translate("TEAM_EXISTS_SYNC")}`}
        </Modal>
        {state.modalState === CBModalState.CAPEGO_FORNOX_MODAL && (
          <CapegoFortnoxConnectionModal
            isOpen
            orgNumber={customer?.company_registration_number || ""}
            onDismiss={closeModal}
          />
        )}
        {state.modalState === CBModalState.SIE_FILES && (
          <SIEFilesModal
            selectedCustomer={customer}
            showSIEFilesModal
            onDismiss={closeModal}
          />
        )}
        {state.modalState === CBModalState.REPORTS && (
          <ReportsModal
            selectedCustomer={customer}
            showReportsModal
            onDismiss={closeModal}
          />
        )}
        {state.modalState === CBModalState.DEFERRED_PAYMENT && (
          <PostponePaymentForInvoiceModal
            isOpen
            customerNumber={customer?.customer_number || ""}
            onDismiss={closeModal}
          />
        )}

        {/* https://lrfkonsult.atlassian.net/browse/ML-932
          Ticket is still in progress, BE fixes pending
          uncomment when done */}
        {/* {state.modalState === CBModalState.TEMPORARY_ACCESS && (
          <TemporaryAccessModal
            isOpen
            customer={customer}
            onDismiss={closeModal}
          />
        )} */}
      </Col>
    </Row>
  );
}
