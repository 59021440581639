import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { Spinner } from "@fluentui/react-components";
import App from "App";
import { appendError } from "state/notifications";
import { useTranslation } from "hooks";
import { setTranslation } from "mgt-config";
import { SpinnerSize } from "components/spinner";

function AppBrowser() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const dispatch = useDispatch();
  const { translate } = useTranslation();

  useEffect(() => {
    setTranslation(translate);
  }, [translate]);

  const authenticate = useCallback(async () => {
    try {
      await Providers.globalProvider.login?.();
    } catch (e) {
      console.error("There was an error when authenticating.", e);
      dispatch(appendError("FAILED_TO_LOG_IN", e as Error, 0));
      throw e;
    }
  }, [dispatch]);

  useEffect(() => {
    setTranslation(translate);
  }, [translate]);

  useEffect(() => {
    const updateState = async () => {
      const { state } = Providers.globalProvider;

      if (state === ProviderState.SignedOut) {
        await authenticate();
      }
      setIsSignedIn(state === ProviderState.SignedIn);
    };

    Providers.onProviderUpdated(updateState);
    updateState();

    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    };
  }, [authenticate]);

  if (
    !isSignedIn ||
    Providers.globalProvider.state === ProviderState.Loading ||
    Providers.globalProvider.state === ProviderState.SignedOut
  ) {
    return (
      <div className="p-lg text-center d-flex align-items-center justify-content-center">
        <Spinner
          labelPosition="below"
          appearance="primary"
          size={SpinnerSize.ExtraSmall}
          label={translate("LOGGING_IN")}
        />
      </div>
    );
  }

  return <App />;
}

export default AppBrowser;
