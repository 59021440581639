import { ArrowLeft20Regular, ArrowRight20Filled } from "@fluentui/react-icons";
import { PersonViewType } from "@microsoft/mgt-react";
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  isEqual,
  startOfDay,
  startOfMonth,
  startOfYear,
} from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner, Tag } from "@fluentui/react-components";

import { DateRange } from "components/date/DateRange";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { PersonPicker, Persona } from "components/people";
import Switch from "components/switch";
import { useTranslation } from "hooks";
import { checkDateRangeOverlap } from "libs/date/check-date-range-overlap";
import { DateFormat, dateLanguageFormatter } from "libs/date/date-format";
import { PersonDetails } from "models/profile";
import { UserSnapshot } from "models/user";
import { RootState } from "state";
import {
  assignUserToActivities,
  fetchActivitiesServicesForCustomersV2,
  getActivitiesPerAssignedUser,
} from "state/activities/actions";
import { AppDispatch } from "state/use-app-redux";
import "./ChangeAssigneeModal.scss";
import { GetActivitiesPerAssignedUserResponse } from "models/activities/activity";
import ConditionalWrapper from "components/conditionalWrapper";
import { IS_DEBUGGING } from "App";
import ChangeAssigneeExpandedDetailsTable from "./ChangeAssigneeExpandedDetailsTable";

const EMPTY = "N/A";

type Props = {
  onCancel: () => void;
  assignee: UserSnapshot;
  selectedCustomerNumbers: string[];
  resetSelectedCustomerNumbers: () => void;
};

enum PillsShowDeliveriesOrServicesEnum {
  Deliveries,
  Services,
  Both,
}
type PillsShowDeliveriesOrServicesType =
  | PillsShowDeliveriesOrServicesEnum.Deliveries
  | PillsShowDeliveriesOrServicesEnum.Services
  | PillsShowDeliveriesOrServicesEnum.Both;

type PillsDataType = number | typeof EMPTY;

interface PillsData {
  customers: PillsDataType;
  deliveries: PillsDataType;
  services: PillsDataType;
  activities: PillsDataType;
}

const emptyPillsData: PillsData = {
  customers: EMPTY,
  deliveries: EMPTY,
  services: EMPTY,
  activities: EMPTY,
};

export interface ActivityGroupedByCustomerId {
  customerId: string;
  activities: GetActivitiesPerAssignedUserResponse[];
  distinctActivityTypes: string[];
}

export default function ChangeAssigneeModal({
  onCancel,
  assignee,
  selectedCustomerNumbers,
  resetSelectedCustomerNumbers,
}: Props) {
  const { translate, language } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const dateFormatter = useMemo(
    () => dateLanguageFormatter(language, DateFormat.YearDayMonth),
    [language]
  );

  const {
    timelineWindow2,
    definitions: { dataMap: definitionsMap },
  } = useSelector((state: RootState) => state.activities);
  const { currentUser } = useSelector((state: RootState) => state.users);

  const [pillsShowDeliveriesOrServices] =
    useState<PillsShowDeliveriesOrServicesType>(
      PillsShowDeliveriesOrServicesEnum.Services
    );
  const [isSavingLoading, setIsSavingLoading] = useState(false);
  const [isExtendedDetailsLoading, setIsExtendedDetailsLoading] =
    useState(false);
  const [newAssigneeId, setNewAssigneeId] = useState("");
  const [includeUndistributedActivities, setIncludeUndistributedActivities] =
    useState(false);
  const [dateRange, setDateRange] = useState<{
    dateFrom: Date | null;
    dateTo: Date | null;
  }>({ dateFrom: null, dateTo: null });
  const [isExpanded, setIsExpanded] = useState(false);
  const [groupedActivitiesByCustomerId, setGroupedActivitiesByCustomerId] =
    useState<ActivityGroupedByCustomerId[]>([]);
  const [pillsData, setPillsData] = useState<PillsData>(emptyPillsData);

  const previousFormDataRef = useRef({
    dateFrom: dateRange.dateFrom,
    dateTo: dateRange.dateTo,
    newAssigneeId,
    includeUndistributedActivities,
  });

  const isSubmitDisabled = useMemo(
    () => !newAssigneeId || !dateRange.dateFrom || !dateRange.dateTo,
    [dateRange, newAssigneeId]
  );

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      if (!isSubmitDisabled) {
        await fetchActivities(controller.signal);
      }
    };

    const checkIfFormDataHasChanged = () => {
      const dateFromHasChanged = !isEqual(
        previousFormDataRef.current.dateFrom ?? new Date(0),
        dateRange.dateFrom ?? new Date(0)
      );
      const dateToHasChanged = !isEqual(
        previousFormDataRef.current.dateTo ?? new Date(0),
        dateRange.dateTo ?? new Date(0)
      );
      const newAssigneeIdHasChanged =
        previousFormDataRef.current.newAssigneeId !== newAssigneeId;
      const includeUndistributedActivitiesHasChanged =
        previousFormDataRef.current.includeUndistributedActivities !==
        includeUndistributedActivities;

      const dataHasChanged =
        dateFromHasChanged ||
        dateToHasChanged ||
        newAssigneeIdHasChanged ||
        includeUndistributedActivitiesHasChanged;
      return dataHasChanged;
    };

    if (checkIfFormDataHasChanged()) {
      fetchData();

      previousFormDataRef.current = {
        dateFrom: dateRange.dateFrom,
        dateTo: dateRange.dateTo,
        newAssigneeId,
        includeUndistributedActivities,
      };
    }

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateRange.dateFrom,
    dateRange.dateTo,
    newAssigneeId,
    includeUndistributedActivities,
  ]);

  const fetchActivities = async (abortSignal: AbortSignal) => {
    setIsExtendedDetailsLoading(true);
    setGroupedActivitiesByCustomerId([]);

    const affectedActivities = await dispatch(
      getActivitiesPerAssignedUser({
        abortSignal,
        requestBody: {
          customer_ids: selectedCustomerNumbers,
          current_user: assignee.id,
          start_date: formatDate(dateRange.dateFrom),
          end_date: formatDate(dateRange.dateTo),
          include_unassigned: includeUndistributedActivities,
        },
      })
    ).unwrap();

    if (abortSignal.aborted) {
      return;
    }

    setIsExtendedDetailsLoading(false);

    if (affectedActivities) {
      processAffectedActivities(affectedActivities);
    }
  };

  const processAffectedActivities = (
    affectedActivities: GetActivitiesPerAssignedUserResponse[]
  ) => {
    const newPillsData: PillsData = {
      ...emptyPillsData,
      activities: affectedActivities.length,
    };

    const groupedByCustomerId: ActivityGroupedByCustomerId[] = [];

    affectedActivities.forEach((activity) => {
      const existingCustomerGroup = groupedByCustomerId.find(
        (group) => group.customerId === activity.customer_id
      );

      if (existingCustomerGroup) {
        existingCustomerGroup.activities.push(activity);
      } else {
        groupedByCustomerId.push({
          customerId: activity.customer_id,
          activities: [activity],
          distinctActivityTypes: [],
        });
      }
    });
    newPillsData.customers = groupedByCustomerId.length;

    if (
      pillsShowDeliveriesOrServices ===
        PillsShowDeliveriesOrServicesEnum.Deliveries ||
      pillsShowDeliveriesOrServices === PillsShowDeliveriesOrServicesEnum.Both
    ) {
      // The total number of deliveries is calculated as the sum of unique
      // deliveries for each customer. A delivery is considered unique
      // for a customer if the "delivery_name" is present. Additionally,
      // if "type_ext" exists and is non-empty, it is combined with
      // "delivery_name" to form a unique identifier for the delivery. This
      // unique identifier is then used to count the total number of unique
      // deliveries for each customer.
      const numberOfUniqueDeliveries = groupedByCustomerId.reduce(
        (totalUniqueDeliveries, customerGroup) => {
          const uniqueDeliveriesForCustomer = new Set(
            customerGroup.activities
              .filter((activity) => !!activity.delivery_name)
              .flatMap((activity) => {
                let uniqueDelivery = activity.delivery_name;
                if (activity.type_ext && activity.type_ext.trim() !== "") {
                  uniqueDelivery += `--${activity.type_ext}`;
                }
                return uniqueDelivery;
              })
          );
          return totalUniqueDeliveries + uniqueDeliveriesForCustomer.size;
        },
        0
      );
      newPillsData.deliveries = numberOfUniqueDeliveries;
    }

    if (
      pillsShowDeliveriesOrServices ===
        PillsShowDeliveriesOrServicesEnum.Services ||
      pillsShowDeliveriesOrServices === PillsShowDeliveriesOrServicesEnum.Both
    ) {
      groupedByCustomerId.forEach((customerGroup) => {
        customerGroup.distinctActivityTypes = Array.from(
          new Set(
            customerGroup.activities.map((activity) => activity.activity_type)
          )
        ).sort((activityTypeA, activityTypeB) => {
          const definitionA = definitionsMap[activityTypeA];
          const definitionB = definitionsMap[activityTypeB];

          const categoryComparison =
            definitionA.category_sorting_nr - definitionB.category_sorting_nr;
          if (categoryComparison !== 0) {
            return categoryComparison;
          }
          return (
            definitionA.activity_sorting_nr - definitionB.activity_sorting_nr
          );
        });
      });

      newPillsData.services = groupedByCustomerId.reduce(
        (totalServices, customerGroup) =>
          totalServices + customerGroup.distinctActivityTypes.length,
        0
      );
    }

    setGroupedActivitiesByCustomerId(groupedByCustomerId);

    setPillsData(newPillsData);
  };

  const onPersonChange = (person?: PersonDetails) => {
    if (!person?.id) {
      setNewAssigneeId("");
      return;
    }

    setNewAssigneeId(person.id);
  };

  const formatDate = (date: Date | null) => (date ? dateFormatter(date) : "");

  const onSave = async () => {
    setIsSavingLoading(true);

    const affectedActivities = await dispatch(
      assignUserToActivities({
        customer_ids: selectedCustomerNumbers,
        current_user: assignee.id,
        new_user: newAssigneeId,
        start_date: formatDate(dateRange.dateFrom),
        end_date: formatDate(dateRange.dateTo),
        include_unassigned: includeUndistributedActivities,
      })
    ).unwrap();

    if (affectedActivities === 0) {
      resetSelectedCustomerNumbers();
    } else if (affectedActivities && dateRange.dateFrom && dateRange.dateTo) {
      resetSelectedCustomerNumbers();

      const timelineDateFrom = startOfMonth(timelineWindow2[0]);
      const timelineDateTo = endOfMonth(
        timelineWindow2[timelineWindow2.length - 1]
      );
      const { dateFrom } = dateRange;
      const dateTo = endOfDay(dateRange.dateTo);

      const hasOverlap = checkDateRangeOverlap(
        { from: timelineDateFrom, to: timelineDateTo },
        { from: dateFrom, to: dateTo }
      );
      if (hasOverlap) {
        dispatch(fetchActivitiesServicesForCustomersV2({}));
      }
    }

    setIsSavingLoading(false);
    onCancel();
  };

  const getExpandedTitle = () => {
    let title = translate("CB.CHANGE_ASSIGNEE_MODAL.HEADER_EXPANDED", [
      assignee.name?.split(" ")?.[0] ?? "",
    ]);

    if (!isExtendedDetailsLoading && pillsData.activities !== EMPTY) {
      title += ` (${pillsData.activities})`;
    }

    return title;
  };

  const renderPillNumber = (number: PillsDataType) => {
    return (
      <ConditionalWrapper
        condition={number === EMPTY}
        wrapper={<span className="body-italic-light" />}
      >
        {number}
      </ConditionalWrapper>
    );
  };

  return (
    <Modal
      size={isExpanded ? "large" : "medium"}
      dialogContentClassName="mt-0 pt-0"
      isOpen
      hideCloseButton={!isExpanded}
      dialogActionsPosition={isExpanded ? "start" : "end"}
      onDismiss={onCancel}
      header={
        isExpanded ? (
          <ModalHeader headerTitleText={getExpandedTitle()} />
        ) : (
          <ModalHeader
            className="mb-sm"
            headerTitleText={translate("CB.CHANGE_ASSIGNEE_MODAL.HEADER", [
              assignee.name?.split(" ")?.[0] ?? "",
            ])}
            subheaderContent={
              <Row className="px-sm align-items-center">
                <Col xs="auto" className="d-flex fg-lg">
                  <Tag className="customer-details-pill">
                    <span className="fw-bold fpx-12">
                      {translate("CB.CHANGE_ASSIGNEE_MODAL.PILL_1")}:{" "}
                      {renderPillNumber(pillsData.customers)}
                    </span>
                  </Tag>

                  {(pillsShowDeliveriesOrServices ===
                    PillsShowDeliveriesOrServicesEnum.Deliveries ||
                    pillsShowDeliveriesOrServices ===
                      PillsShowDeliveriesOrServicesEnum.Both) && (
                    <Tag className="customer-details-pill">
                      <span className="fw-bold fpx-12">
                        {translate("CB.CHANGE_ASSIGNEE_MODAL.PILL_2")}:{" "}
                        {renderPillNumber(pillsData.deliveries)}
                      </span>
                    </Tag>
                  )}

                  {(pillsShowDeliveriesOrServices ===
                    PillsShowDeliveriesOrServicesEnum.Services ||
                    pillsShowDeliveriesOrServices ===
                      PillsShowDeliveriesOrServicesEnum.Both) && (
                    <Tag className="customer-details-pill">
                      <span className="fw-bold fpx-12">
                        {translate("CB.CHANGE_ASSIGNEE_MODAL.PILL_3")}:{" "}
                        {renderPillNumber(pillsData.services)}
                      </span>
                    </Tag>
                  )}

                  <Tag className="customer-details-pill">
                    <span className="fw-bold fpx-12">
                      {translate("CB.CHANGE_ASSIGNEE_MODAL.PILL_4")}:{" "}
                      {renderPillNumber(pillsData.activities)}
                    </span>
                  </Tag>
                </Col>
                <Col>
                  {isExtendedDetailsLoading ? (
                    <Spinner size="extra-small" />
                  ) : (
                    <div className="vertical-divider fit-content m-auto" />
                  )}
                </Col>
                <Col xs="auto" className="align-self-end">
                  <Button
                    appearance="transparent"
                    className="blue-link-underline px-0"
                    onClick={() => setIsExpanded(true)}
                    disabled={
                      !isExtendedDetailsLoading &&
                      (isSavingLoading || pillsData.activities === EMPTY)
                    }
                  >
                    {translate("CB.CHANGE_ASSIGNEE_MODAL.EXPAND_BUTTON")}
                  </Button>
                </Col>
              </Row>
            }
          />
        )
      }
      footer={
        isExpanded ? (
          <Row className="justify-content-start">
            <Col xs="auto">
              <Button
                className="py-sm"
                icon={<ArrowLeft20Regular />}
                iconPosition="before"
                onClick={() => setIsExpanded(false)}
              >
                {translate("CB.CHANGE_ASSIGNEE_MODAL.CANCEL_LABEL_EXPANDED")}
              </Button>
            </Col>
          </Row>
        ) : (
          <ModalFooter
            onCancel={onCancel}
            onSave={onSave}
            labelCancel="CB.CHANGE_ASSIGNEE_MODAL.CANCEL_LABEL"
            labelSubmit="CB.CHANGE_ASSIGNEE_MODAL.SUBMIT_LABEL"
            isDisabled={isSubmitDisabled}
            isLoading={isSavingLoading}
          />
        )
      }
    >
      <div className="change-assignee-modal-body">
        {isExpanded &&
          (isExtendedDetailsLoading ? (
            <Spinner size="huge" className="zoom-2 my-xxl" />
          ) : (
            <ChangeAssigneeExpandedDetailsTable
              groupedActivitiesByCustomerId={groupedActivitiesByCustomerId}
            />
          ))}

        {!isExpanded && (
          <>
            {IS_DEBUGGING && (
              <button
                type="button"
                onClick={() => {
                  setNewAssigneeId("8f2efd38-fa0d-427c-b915-4a7ba7d8c014"); // Viktoria
                  setDateRange({
                    dateFrom: startOfYear(new Date()),
                    dateTo: startOfDay(endOfYear(new Date())),
                  });
                  setIncludeUndistributedActivities(true);
                }}
              >
                DEBUGGING: Fetch for whole year
              </button>
            )}

            <hr className="mt-0" />

            <div className="change-assignee-modal-person-pickers-row fw-semibold">
              <div>
                <Row className="mb-xs">
                  <Col>
                    {translate("CB.CHANGE_ASSIGNEE_MODAL.CURRENT_ASSIGNEE")}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Persona
                      userId={assignee.id}
                      view={PersonViewType.oneline}
                      className="disabled"
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <ArrowRight20Filled />
              </div>
              <div className="pl-lg">
                <Row className="mb-xs">
                  <Col>
                    {translate("CB.CHANGE_ASSIGNEE_MODAL.NEW_ASSIGNEE")} *
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PersonPicker
                      selectionMode="single"
                      emptyPlaceholder={translate(
                        "CB.CHANGE_ASSIGNEE_MODAL.PERSON_PICKER.PLACEHOLDER"
                      )}
                      excludeMe={assignee.id === currentUser.graphId}
                      userFilters={`not(id eq '${assignee.id}')`}
                      selectedUsers={
                        newAssigneeId ? [{ id: newAssigneeId }] : []
                      }
                      onPersonChange={(_, __, person) => onPersonChange(person)}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <hr className="mb-lg" />

            <DateRange
              className="w-100"
              label={`${translate(
                "CB.CHANGE_ASSIGNEE_MODAL.DATE_RANGE_LABEL"
              )} *`}
              dateFormatter={dateFormatter}
              dateRange={{
                start: {
                  date: dateRange.dateFrom,
                  onSelectDate: (date) =>
                    setDateRange((prev) => ({ ...prev, dateFrom: date })),
                },
                end: {
                  date: dateRange.dateTo,
                  onSelectDate: (date) =>
                    setDateRange((prev) => ({ ...prev, dateTo: date })),
                },
              }}
              onClearDateRange={() => setIncludeUndistributedActivities(false)}
            />

            <Switch
              className="mt-md"
              label={translate(
                "CB.CHANGE_ASSIGNEE_MODAL.INCLUDE_ALL_CHECKBOX_LABEL"
              )}
              checked={includeUndistributedActivities}
              onToggleMethod={(_, { checked }) =>
                setIncludeUndistributedActivities(checked)
              }
              noLeftMargin
              disabled={!dateRange.dateFrom || !dateRange.dateTo}
            />
          </>
        )}
      </div>
    </Modal>
  );
}
