import { UseFormReturn } from "react-hook-form";

import { useTranslation } from "hooks/use-translate";
import { useIsExistingCustomer } from "helpers/useIsExistingCustomer";
import { isValidOrganizationNumberOrSSN } from "libs/is-valid-organization-number-or-ssn";
import { isValidSwedishZipcode } from "libs/is-valid-zipcode";
import { getDigitsFromString, normalizeOrgNrAndSSN } from "libs/number-format";
import { MAX_CHAR_ADDRESS, MAX_CHAR_CITY } from "constants/maxCharConsts";
import {
  CompanyInformation,
  CompanyTypeListItem,
  ValidLegalFormCodes,
} from "models/offer/Company";
import { RHFInput } from "components/input/RHFInput";
import { DropdownV9 } from "components/dropdown/DropdownV9";

type DealInformationRequiredCompanyFormProps = {
  formMethods: UseFormReturn<Partial<CompanyInformation>>;
  companyTypeList: CompanyTypeListItem[];
  orgNum?: string;
};

export function DealInformationRequiredCompanyForm({
  formMethods,
  companyTypeList,
  orgNum,
}: DealInformationRequiredCompanyFormProps) {
  const { translate } = useTranslation();

  const { isLoadingCustomer, isExistingCustomer } = useIsExistingCustomer();

  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    getValues,
  } = formMethods;

  const companyTypeOptions = [
    ...companyTypeList.map((item) => ({
      value: item.WKName,
      text: item.NameSE,
    })),
  ];

  const organizationNumberValidation = register("org_number", {
    required: translate("ORGANIZATION_NUMBER_REQUIRED"),
    validate: {
      isValid: async (number) => {
        if (!number) {
          return true;
        }
        if (orgNum && number === orgNum) {
          return true;
        }
        if (
          number.length !== 10 ||
          !isValidOrganizationNumberOrSSN(number, true)
        ) {
          return translate("INVALID_ORGNR_OR_SSN_MESSAGE");
        }
      },
    },
  });

  const addressOptions = register("address", {
    required: translate("ADDRESS_REQUIRED"),
  });

  const cityOptions = register("city", {
    required: translate("POSTAL_TOWN_REQUIRED"),
  });

  const postalCodeOptions = register("zip", {
    required: translate("POSTAL_CODE_REQUIRED"),
    validate: {
      isValid: (postalCode) => {
        if (!postalCode) {
          return translate("POSTAL_CODE_REQUIRED");
        }
        return isValidSwedishZipcode(postalCode)
          ? true
          : translate("POSTAL_CODE_INVALID");
      },
    },
  });

  const companyTypeInputOptions = register("company_type", {
    required: translate("COMPANY_TYPE_REQUIRED"),
    validate: (value) =>
      companyTypeList
        .map((cti) => cti.WKName)
        .includes(value as ValidLegalFormCodes) || "invalid",
  });

  const handleOrgNumberChange = async (orgNumber: string) => {
    const normalizedNumber = normalizeOrgNrAndSSN(orgNumber);
    const customer = await isExistingCustomer(normalizedNumber);
    if (customer && customer.customer_number) {
      formMethods.reset(customer);
    }
    setValue("org_number", getDigitsFromString(orgNumber), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <form>
      <div className="pb-md">
        <label>{translate("ORG_NUM")} *</label>
        <RHFInput
          className="deal-input"
          register={organizationNumberValidation}
          placeholder={translate("ORG_NUM")}
          onChange={(e) => {
            handleOrgNumberChange(e.target.value);
          }}
          disabled={isLoadingCustomer}
          errorMessage={errors.org_number?.message}
        />
      </div>
      <div className="pb-md">
        <label>{translate("CITY")} *</label>
        <RHFInput
          className="deal-input"
          register={cityOptions}
          placeholder={translate("CITY")}
          value={getValues("city")}
          maxChar={MAX_CHAR_CITY}
          disabled={isLoadingCustomer}
          onChange={(e) => {
            setValue("city", e.target.value, {
              shouldValidate: true,
            });
          }}
          errorMessage={errors.city?.message}
        />
      </div>
      <div className="pb-md">
        <label>{translate("ZIP")} *</label>
        <RHFInput
          className="deal-input"
          register={postalCodeOptions}
          placeholder={translate("ZIP")}
          disabled={isLoadingCustomer}
          onChange={(e) => {
            setValue("zip", e.target.value, {
              shouldValidate: true,
            });
          }}
          errorMessage={errors.zip?.message}
        />
      </div>
      <div className="pb-md">
        <label>{translate("ADDRESS")} *</label>
        <RHFInput
          className="deal-input"
          register={addressOptions}
          placeholder={translate("ADDRESS")}
          value={getValues("address")}
          maxChar={MAX_CHAR_ADDRESS}
          disabled={isLoadingCustomer}
          onChange={(e) => {
            setValue("address", e.target.value, {
              shouldValidate: true,
            });
          }}
          errorMessage={errors.address?.message}
        />
      </div>
      <div className="pb-md">
        <label>{translate("CUSTOMER_TYPE")} *</label>
        <DropdownV9
          className={`deal-dropdown ${
            isLoadingCustomer ? "deal-dropdown-disabled" : ""
          }`}
          options={companyTypeOptions}
          formRegister={companyTypeInputOptions}
          disabled={isLoadingCustomer}
          placeholder={translate("SELECT_CUSTOMER_TYPE")}
          onOptionSelect={(_, data) => {
            setValue("company_type", data.optionValue, {
              shouldValidate: true,
            });
          }}
          onBlur={() => {
            trigger("company_type");
          }}
          errorMessage={errors.company_type?.message}
          selectedOptions={[getValues("company_type") ?? ""]}
        />
      </div>
    </form>
  );
}
