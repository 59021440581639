import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DrawerBody, OverlayDrawer } from "@fluentui/react-components";

import { useTranslation } from "hooks";
import { formatDate } from "libs/date/date-format";
import { numberAsSwedishCurrency } from "libs/number-format";
import { Deal, PotentialDealItem } from "models/deals/deal";
import { RootState } from "state";
import { appendToastMessage } from "state/notifications";
import { resetCurrentDeal, setCurrentDeal } from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import DrawerHeader from "components/drawer/drawerHeader/DrawerHeader";
import { PotentialDealsGrid } from "shared/potentialDealsGrid/PotentialDealsGrid";
import "./potentialDeals.scss";

interface IPotentialDealsProps {
  isInPanelDisplay?: boolean;
  filteredPotentialDeals: Deal[];
}

function PotentialDeals({
  isInPanelDisplay = false,
  filteredPotentialDeals,
}: IPotentialDealsProps) {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const [showPanel, setShowPanel] = useState(isInPanelDisplay);
  const [selectedDealId, toggleSelectedDealId] = useState("");

  const {
    dealOptions: { data: dealOptions },
    pipelines: { data: pipelines },
    products: { data: products },
  } = useSelector((state: RootState) => state.sales);

  const dealSources = dealOptions.find((d) => d.name === "deal_source");

  const items: PotentialDealItem[] = filteredPotentialDeals.map((deal) => {
    return {
      dealId: deal.id,
      // eslint-disable-next-line no-underscore-dangle
      businessArea: deal.affarsomrade_deal_,
      dateCreatedFormatted: formatDate(deal.createdAt),
      dateCreatedAsMilliseconds: new Date(deal.createdAt).getTime(),
      sourceValue: parseInt(deal.deal_source, 10),
      sourceLabel:
        dealSources?.options.find((o) => o.value === deal.deal_source)?.label ||
        "",
      services: products
        .filter((product) => deal.productIds?.includes(product.id))
        .map((product) => product.name),
      businessValueAmount: deal.amount,
      businessValueAmountFormatted:
        deal.amount !== undefined
          ? numberAsSwedishCurrency(deal.amount, true)
          : "",
      contactPersonName: deal.contact
        ? `${deal.contact.firstname} ${deal.contact.lastname}`
        : "",
      stage:
        pipelines
          .find((pipeline) => pipeline.id === deal.pipeline)
          ?.stages.find((stage) => stage.id === deal.dealstage)?.label || "",
      responsiblePersonEmail: deal.deal_assignee_email || "",
    };
  });

  const togglePotentialDeal = (item: PotentialDealItem, checked: boolean) => {
    if (checked) {
      dispatch(appendToastMessage("POTENTIAL_DEALS_START_OFFER_TOAST", "info"));
      const hubspotDeal = filteredPotentialDeals.find(
        (deal) => deal.id === item.dealId
      );
      if (hubspotDeal) {
        dispatch(setCurrentDeal(hubspotDeal));
      }
    } else {
      dispatch(resetCurrentDeal());
    }
    toggleSelectedDealId(checked ? item.dealId : "");
  };

  return (
    <div>
      {filteredPotentialDeals.length > 0 ? (
        <>
          <div className="stack-horizontal">
            <PotentialDealsGrid
              togglePotentialDeal={togglePotentialDeal}
              potentialDeals={filteredPotentialDeals}
              selectedDealId={selectedDealId}
              items={items}
              shouldToggleDeal
            />
            {filteredPotentialDeals.length > 1 && (
              <div
                className="ml-md mb-lg cursor-pointer pog-link d-flex align-items-center"
                onClick={() => {
                  setShowPanel(!showPanel);
                }}
              >
                <span className="blue-link-underline text-wrap-none fw-semibold counter-number pl-md">
                  {translate("SHOW_ALL")}
                </span>
              </div>
            )}
          </div>
          {filteredPotentialDeals.length > 1 && (
            <OverlayDrawer open={showPanel} position="end" size="large">
              <DrawerHeader
                onDismiss={() => setShowPanel(false)}
                header="BUSINESS_OPPORTUNITIES"
              />
              <DrawerBody>
                <PotentialDealsGrid
                  potentialDeals={filteredPotentialDeals}
                  items={items}
                  isExpandedView
                  togglePotentialDeal={togglePotentialDeal}
                  selectedDealId={selectedDealId}
                  shouldToggleDeal
                />
              </DrawerBody>
            </OverlayDrawer>
          )}
        </>
      ) : (
        <span className="text-color-gray-700">
          {translate("BUSINESS_OPPORTUNITIES_NOT_FOUND")}
        </span>
      )}
    </div>
  );
}

export default PotentialDeals;
