import { Spinner } from "@fluentui/react-components";
import { useContext } from "react";
import { Badge, Col, Collapse, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ArrowMaximize20Regular } from "@fluentui/react-icons";

import { SectionContext } from "components/section/Section";
import { SectionBody } from "components/section/SectionBody";
import SectionSummaryHeader from "components/section/SectionSummaryHeader";
import { SpinnerSize } from "components/spinner";
import Switch from "components/switch";
import { useTranslation } from "hooks";
import { isDealEditable } from "libs/customerdeal-helpers";
import { getNameFromEmail } from "libs/name-from-email";
import { numberAsSwedishCurrency, numberFormat } from "libs/number-format";
import { useServiceMatrix } from "libs/service-matrix";
import {
  ServiceCategory,
  ServiceGroup,
  ServiceLine,
} from "models/offer/ServiceLine";
import { RootState } from "state";
import { OfferRouteHelper } from "views/offer/wizard/offerRoutes";

interface ServiceInfoTableProps {
  serviceLines: ServiceLine[];
  onToggleApproveServiceCategory: (serviceCategory: ServiceCategory) => void;

  isLoading?: boolean;
}

function ServiceInfoTable({
  serviceLines = [],
  onToggleApproveServiceCategory,
  isLoading = false,
}: ServiceInfoTableProps) {
  const { translate, ts } = useTranslation();

  const { GetFinalPrice, GetTotalSuggestedPrice, GetTotalAdjustments } =
    useServiceMatrix();
  const { isContentExpanded } = useContext(SectionContext);

  const {
    currentOffer: { data: currentOffer },
  } = useSelector((state: RootState) => state.offers);

  const serviceGroups = serviceLines.flatMap((sl) => sl.service_groups);

  const renderFlexSpinner = (
    <span className="d-flex">
      <Spinner size={SpinnerSize.ExtraSmall} />
    </span>
  );
  const renderContentOrSpinner = (content: string) => {
    if (isLoading) {
      return renderFlexSpinner;
    }
    return content;
  };

  function renderEditSectionLink(
    serviceCategory: ServiceCategory,
    serviceGroupName: string
  ) {
    if (!isDealEditable(currentOffer)) {
      return;
    }

    return (
      <Link
        key={serviceCategory.id}
        to={OfferRouteHelper.getServices(serviceGroupName, true)}
        className="text-decoration-none"
      >
        <span className="text-decoration-none pl-md pog-link">
          <ArrowMaximize20Regular />
        </span>
      </Link>
    );
  }

  const renderServiceGroupColumn = (serviceGroup: ServiceGroup) =>
    serviceGroup.service_categories.map((serviceCategory, index) => {
      const projectManager = getNameFromEmail(serviceCategory.project_manager);

      return (
        <Col key={`${serviceCategory.name}-${serviceCategory.id}`}>
          <Row className="py-sm sub-header">
            <Col>
              {ts(serviceCategory.name)}
              {renderEditSectionLink(serviceCategory, serviceGroup.name)}
            </Col>
          </Row>
          <Row className="py-sm">
            <Col>{serviceCategory.start_date ?? " "}</Col>
          </Row>
          <Row className="py-sm">
            <Col>{projectManager}</Col>
          </Row>
          <Row className="py-sm">
            <Col>{ts(serviceCategory.frequency)}</Col>
          </Row>
          <Row className="py-sm">
            <Col className="approve-service-category-toggle">
              <Switch
                disabled
                checked
                label=""
                onToggleMethod={() => {
                  onToggleApproveServiceCategory(serviceCategory);
                }}
              />
            </Col>
          </Row>
          <Collapse in={isContentExpanded}>
            <div>
              <Row className="py-sm price-footer">
                <Col>
                  {renderContentOrSpinner(
                    numberAsSwedishCurrency(serviceCategory.fixed_price)
                  )}
                </Col>
              </Row>
              <Row className="py-sm price-footer">
                <Col>
                  {renderContentOrSpinner(
                    numberAsSwedishCurrency(serviceCategory.dynamic_price)
                  )}
                </Col>
              </Row>
              <Row className="py-sm price-footer">
                <Col>
                  {renderContentOrSpinner(
                    numberAsSwedishCurrency(serviceCategory.hourly_price)
                  )}
                </Col>
              </Row>
              <Row className="py-sm price-footer">
                <Col>
                  {renderContentOrSpinner(
                    numberAsSwedishCurrency(
                      GetTotalSuggestedPrice(serviceCategory)
                    )
                  )}
                </Col>
              </Row>
              <Row className="py-sm price-footer">
                <Col>{numberFormat(serviceCategory.estimated_time)}</Col>
              </Row>
              <Row className="py-sm price-footer">
                <Col>{numberFormat(GetTotalAdjustments(serviceCategory))}</Col>
              </Row>
            </div>
          </Collapse>
          <Row className="py-sm price-footer final-price">
            <Col>
              {renderContentOrSpinner(
                numberAsSwedishCurrency(serviceCategory.final_price)
              )}
            </Col>
          </Row>
        </Col>
      );
    });

  const includedServices = serviceLines.flatMap((sl) => sl.service_groups);

  const servicesSubtitle = includedServices.map((includedService) => {
    return ts(includedService.name);
  });

  const allServiceCategories = serviceLines
    .flatMap((sl) => sl.service_groups)
    .flatMap((sg) => sg.service_categories);

  if (!allServiceCategories) {
    throw new Error("No service categories found");
  }

  return (
    <div>
      <SectionSummaryHeader
        title={translate("SERVICES_INCLUDED")}
        subtitles={servicesSubtitle}
        count={includedServices.length}
        collapsibleContent
      />

      <SectionBody>
        <Row className="summary-table" sticky="top">
          <Col md={4} className="mt-0" sticky="top">
            <Row className="py-sm sub-header">
              <Col>{translate("SERVICES")}</Col>
            </Row>
            <Row className="py-sm">
              <Col>{translate("START_DATE_LABEL")}:</Col>
            </Row>
            <Row className="py-sm">
              <Col>{translate("ASSIGNMENT_MANAGER")}</Col>
            </Row>
            <Row className="py-sm">
              <Col>{translate("FREQUENCY")}</Col>
            </Row>
            <Row className="py-sm">
              <Col>{translate("APPROVED_BY_COMPETENT")}</Col>
            </Row>
            <Collapse in={isContentExpanded}>
              <div>
                <Row className="py-sm price-footer">
                  <Col>{translate("FIXED_PRICE")}</Col>
                </Row>
                <Row className="py-sm price-footer">
                  <Col>{translate("DYNAMIC_PRICE")}</Col>
                </Row>
                <Row className="py-sm price-footer">
                  <Col>{translate("HOURLY_PRICE")}</Col>
                </Row>
                <Row className="py-sm price-footer">
                  <Col>{translate("PRICE_PROPOSAL_POG")}</Col>
                </Row>
                <Row className="py-sm price-footer">
                  <Col>{translate("HOURLY_BUDGET_POG")}</Col>
                </Row>
                <Row className="py-sm price-footer">
                  <Col>{translate("ADJUSTED_PRICE")}</Col>
                </Row>
              </div>
            </Collapse>
            <Row className="py-sm price-footer final-price">
              <Col>{translate("FINAL_TOTAL_PRICE")}</Col>
            </Row>
          </Col>

          {serviceGroups.map((sg, index) => renderServiceGroupColumn(sg))}
        </Row>
        <Row className="text-center pt-5">
          <Col xs={12} xl={3} className="m-auto">
            <div className="horizontal-divider" />
          </Col>
          <Col xs={12} xl={4}>
            <Badge className="final-amount">
              <div>{numberAsSwedishCurrency(GetFinalPrice())}</div>
              <div>{translate("TOTAL_EXCLUDING_VAT")}</div>
            </Badge>
            <div className="text-grayed p-2">{translate("TOTAL_ESTIMATE")}</div>
          </Col>
          <Col xs={12} xl={3} className="m-auto">
            <div className="horizontal-divider" />
          </Col>
        </Row>
      </SectionBody>
    </div>
  );
}

export default ServiceInfoTable;
