import { Col, Row } from "react-bootstrap";
import { Warning20Regular } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import { isCurrentMonth } from "libs/date/date-format";
import "./ServicesPerMonthColumn.scss";
import "./ServiceBox.scss";

type ServicesPerMonthCollapsedColumnProps = {
  yearMonth: Date;
  allEmpty?: boolean;
  completedServices: number;
  allServices: number;
  hasOverdueServices?: boolean;
};

export default function ServicesPerMonthCollapsedColumn({
  yearMonth,
  allEmpty = false,
  completedServices,
  allServices,
  hasOverdueServices,
}: ServicesPerMonthCollapsedColumnProps) {
  const { translate } = useTranslation();
  const backgroundColorClass = isCurrentMonth(yearMonth)
    ? "bg-currentMonthColumn"
    : "bg-monthColumn";

  let altMessage = "";
  if (allEmpty) {
    altMessage = translate("NO_ACTIVITIES_FOR_MONTH");
  }

  return (
    <Col className={`mx-xs ${backgroundColorClass}`}>
      <Row
        className={` px-sm d-flex   align-items-center ${backgroundColorClass}`}
        style={{ height: "45px" }}
      >
        <Col>
          {!allEmpty ? (
            <Row>
              <Col md={12}>
                <label className=" d-flex justify-content-center cursor-pointer">
                  {`${completedServices} / ${allServices}`}
                  {hasOverdueServices && (
                    <Warning20Regular className="text-danger ml-sm" />
                  )}
                </label>
                <progress
                  className="service-box-progress-bar"
                  value={completedServices / allServices}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md={12}>
                <label className="d-flex justify-content-center">
                  {altMessage}
                </label>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Col>
  );
}
