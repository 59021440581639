import { ArrowUpRight16Regular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "components/button";
import { useTranslation } from "hooks/use-translate";
import { DetailedInfo } from "models/customerSettings/customerSettingsDetailedInfo";
import { CustomerPageDetailedPageEnum } from "models/enums/CustomerPageDetailedPage.enum";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import { setOpenCreateModalFor } from "state/customerSettings/customerSettingsSlice";
import CustomerPageDetailedInfoContent from "./customerPageDetailedInfoContent";
import CustomerPageDetailedInfoCreateModal from "./customerPageDetailedInfoCreateModal";
import CustomerPageInfoModal from "./customerPageInfoModal";

const MAX_TEXT = 500;
const MAX_ELEMENTS = 3;

type CustomerPageDetailedInfoProps = {
  customerId: string;
  customerManagerId: string;
};

export default function CustomerPageDetailedInfo({
  customerId,
  customerManagerId,
}: CustomerPageDetailedInfoProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { translate } = useTranslation();
  const detailedInfoState = useSelector(
    (state: RootState) => state.settings.detailedInfo
  );
  const { categories, customerdetailssubcategory } = detailedInfoState.data;

  const URLProps = new URLSearchParams(window.location.search);
  const isConveyorBeltV2 = URLProps.get("conveyorBeltV2");

  const serviceLineOptions = useSelector(
    (state: RootState) => state.settings.serviceLines.data
  );
  const openCreateModalFor = useSelector(
    (state: RootState) => state.settings.openCreateModalFor
  );

  const location = useLocation();
  const lastUrlSegment = location.pathname.split("/").pop();
  const isDetailedPage =
    lastUrlSegment ===
    CustomerPageDetailedPageEnum.CUSTOMER_SPECIFIC_INFORMATION;

  const [openModal, setOpenModal] = useState(false);
  const [openDetailModal, setOpenDetailedModal] = useState(false);
  const [currentInfo, setCurrentInfo] = useState<DetailedInfo>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const openDetailedInfoPage = () => {
    navigate(
      isConveyorBeltV2
        ? AppRouteHelper.getCustomerPageSpecificInformationPage(
            customerId,
            true
          )
        : AppRouteHelper.getCustomerPageSpecificInformationPage(customerId)
    );
  };

  useEffect(() => {
    if (
      openCreateModalFor ===
      CustomerPageDetailedPageEnum.CUSTOMER_SPECIFIC_INFORMATION
    ) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [openCreateModalFor]);

  const onEditInfo = async (info: DetailedInfo) => {
    setIsEditMode(true);
    await dispatch(
      setOpenCreateModalFor(
        CustomerPageDetailedPageEnum.CUSTOMER_SPECIFIC_INFORMATION
      )
    );
    setCurrentInfo(info);
    setOpenModal(true);
  };

  return (
    <Row className="m-auto">
      <Col md={12} className="cs-content p-lg">
        <CustomerPageDetailedInfoContent
          maxElements={isDetailedPage ? undefined : MAX_ELEMENTS}
          maxText={isDetailedPage ? undefined : MAX_TEXT}
          onSelectItem={setCurrentInfo}
          setOpenModal={setOpenDetailedModal}
          onEditInfo={onEditInfo}
          isDetailedPage={isDetailedPage}
          customerManagerId={customerManagerId}
        />
      </Col>
      {!isDetailedPage && (
        <Row className="mt-md">
          <Col className="d-flex align-items-end px-0">
            <Button
              variant="outline-outline"
              className="justify-content-start primary-colored-text"
              onClick={() => openDetailedInfoPage()}
            >
              <ArrowUpRight16Regular />
              <span className="d-inline mx-sm fw-bold primary">
                {translate("CSI_NEW_PAGE")}
              </span>
            </Button>
          </Col>
        </Row>
      )}
      {openModal && !isEditMode && (
        <CustomerPageDetailedInfoCreateModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          categories={categories}
          subcategories={customerdetailssubcategory}
          customerId={customerId}
          serviceLines={serviceLineOptions}
        />
      )}
      {openModal && currentInfo && isEditMode && (
        <CustomerPageDetailedInfoCreateModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          categories={categories}
          subcategories={customerdetailssubcategory}
          customerId={customerId}
          serviceLines={serviceLineOptions}
          setIsEditMode={setIsEditMode}
          currentInfo={currentInfo}
        />
      )}

      {!isDetailedPage && openDetailModal && currentInfo && (
        <CustomerPageInfoModal
          currentInfo={currentInfo}
          openModal={openDetailModal}
          setOpenModal={setOpenDetailedModal}
        />
      )}
    </Row>
  );
}
