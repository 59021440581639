import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import AppConfig from "app-config";
import { useAuth } from "auth/use-auth";
import { Dropdown } from "components/dropdown";
import { DropdownV9 } from "components/dropdown/DropdownV9";
import { Input } from "components/input";
import { createNewDealFromContext } from "helpers/BusinessOpportinityHelper";
import { useTranslation } from "hooks/use-translate";
import { CreateDeal, SearchContact } from "models/deals/deal";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { INCLUDED_SOURCES } from "shared/dealConstants";
import { RootState } from "state";
import {
  createNewDeal,
  fetchBusinessOpportunities,
  searchContact,
  updateDealProducts,
  updateDealValues,
} from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import { CreateCustomerContext } from "../CreateCustomerContext";
import WizardSectionCustomer from "../components/WizardSectionCustomer";

export default function CustomerBusinessDetailsPage() {
  const { translate, ts } = useTranslation();
  const { user } = useAuth();

  const dispatch: AppDispatch = useDispatch();
  const { newCustomer, businessAreaSelected, dealIdToUpdate } = useContext(
    CreateCustomerContext
  );

  const {
    currentDeal,
    dealsByOrgNumber: { data: potentialDeals },
    dealOptions: { data: dealOptions },
    products: { data: products },
  } = useSelector((state: RootState) => state.sales);

  let dealToUpdate = currentDeal;
  if (!dealToUpdate && dealIdToUpdate) {
    dealToUpdate = potentialDeals.find((deal) => deal.id === dealIdToUpdate);
  }

  const [creatingOrUpdatingDeal, setCreatingOrUpdatingDeal] = useState(false);
  const dealSources = dealOptions.find((d) => d.name === "deal_source");
  const dealsBusinessAreas = dealOptions.find(
    (d) => d.name === "affarsomrade_deal_"
  );

  const getDefaultDeal = (userEmail: string) => {
    return {
      first_name: "",
      last_name: "",
      amount: dealToUpdate?.amount ?? 0,
      contact_email_searched: false,
      contact: undefined,
      customer_type: "",
      contact_email: "",
      phone: "",
      mobile: "",
      assignee_email: "",
      assignee_firstname: "",
      assignee_lastname: "",
      organisationsnummer: "",
      pipeline: AppConfig.FEATURES.DEALS.DEFAULT_PIPELINE,
      dealstage: AppConfig.FEATURES.DEALS.DEFAULT_DEAL_STAGE,
      dealname: "",
      meddelande_salj: "",
      company: "",
      deal_source: dealToUpdate?.deal_source ?? "",
      affarsomrade_deal_: "",
      aterkommande_aktiviteter: "",
      km_deal_created_by_user: userEmail,
      productIds: dealToUpdate?.productIds ?? [],
      postal_address: "",
      postal_city: "",
      postnummer: "",
    };
  };

  const [newDeal, setNewDeal] = useState<CreateDeal>(
    getDefaultDeal(user?.username ?? "")
  );

  const [selectedProductOptions, setProductOptions] = useState<string[]>(
    dealToUpdate?.productIds ?? []
  );
  const [dealAmount, setDealAmount] = useState<number | undefined>(
    dealToUpdate?.amount
  );

  const [searchContactLoading, setSearchContactLoading] = useState(false);

  let dealSourceOptions = dealSources?.options || [];
  if (!dealToUpdate) {
    dealSourceOptions = dealSourceOptions.filter((option) =>
      INCLUDED_SOURCES.includes(option.value)
    );
  }

  const mapSearchContactToDealContact = (contact: SearchContact) => ({
    id: contact.id,
    email: contact.email,
    firstname: contact.firstName,
    lastname: contact.lastName,
    mobilephone: contact.mobile,
    phone: contact.phone,
    aterkommande_aktiviteter: contact.aterkommande_aktiviteter,
    aktivitet_event: contact.aktivitet_event,
  });

  useEffect(() => {
    const init = async () => {
      setSearchContactLoading(true);

      const contacts = await dispatch(
        searchContact(newCustomer.ElectronicMailaddress)
      );
      const contactsFound = contacts && contacts.length !== 0;
      setNewDeal({
        ...newDeal,
        contact_email: newCustomer.ElectronicMailaddress,
        contact_email_searched: true,
        aterkommande_aktiviteter: contactsFound
          ? contacts[0].aterkommande_aktiviteter
          : "",
        contact: contactsFound
          ? mapSearchContactToDealContact(contacts[0])
          : undefined,
      });

      setSearchContactLoading(false);
    };

    init();

    // Adding required items creates circular dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productOptions = products.map((p) => ({
    text: p.name,
    data: p,
    value: p.id,
  }));

  const productOptionsByArea = productOptions.filter(
    (po) => po.data?.activity_category_label === businessAreaSelected[0]
  );

  const postBusinessDetails = async () => {
    setCreatingOrUpdatingDeal(true);

    try {
      if (dealToUpdate) {
        await updateDeal(dealToUpdate.id);
      } else {
        await createDeal();
      }
    } catch (error) {
      return false;
    } finally {
      await dispatch(fetchBusinessOpportunities());
      setCreatingOrUpdatingDeal(false);
    }

    return true;
  };

  const createDeal = async () => {
    const businessArea = dealsBusinessAreas?.options.find(
      (area) => area.label === businessAreaSelected[0]
    )?.value;

    const dealToCreate = createNewDealFromContext(
      {
        ...newDeal,
        affarsomrade_deal_: businessArea || "",
        dealstage: AppConfig.FEATURES.DEALS.DEAL_STAGE_WON,
        dealname: `${newCustomer.FirstName} ${newCustomer.LastName} | ${businessArea}`,
        productIds: selectedProductOptions,
        supress_asignee_email: true,
        amount: dealAmount ?? 0,
      },
      user,
      undefined,
      true,
      true
    );
    return dispatch(createNewDeal(dealToCreate));
  };

  const updateDeal = async (dealId: string) => {
    await dispatch(updateDealProducts(dealId, selectedProductOptions, false));
    await dispatch(
      updateDealValues(dealId, {
        amount: dealAmount,
        hubspot_company: {
          name: newCustomer.CompanyName,
          org_number: newCustomer.CompanyRegistrationNumber,
          city: newCustomer.PostalDistrict,
          zip: newCustomer.ZipCode,
          address: newCustomer.PostalAddress,
          company_type: newCustomer.CompanyForm,
        },
        contact: newDeal.contact ? newDeal.contact : undefined,
      })
    );
  };

  // 1. If there's no deal to update (dealToUpdate), and potentialDeals is empty,
  // that means the button needs to be disabled because the previous step is the
  // contact details page.
  //
  // 2. If there's a deal to update and that deal is coming from currentDeal,
  // then the button needs to be disabled because the previous step again is the
  // contact details page.
  //
  // 3. If there's a deal to update and that deal is coming from dealIdToUpdate,
  // then the button needs to be enabled because the previous step is the potential
  // deals page, which means we should be able to go back to that page.
  const disabledBackMessage = () => {
    let translationKey = "";

    if (!dealToUpdate && !potentialDeals.length) {
      translationKey = "BUSINESS_DETAILS_DISABLE_BACK_MESSAGE_CREATE";
    }

    if (currentDeal) {
      translationKey = "BUSINESS_DETAILS_DISABLE_BACK_MESSAGE_UPDATE";
    }

    if (translationKey) {
      return <div className="text-center">{ts(translationKey)}</div>;
    }
  };

  const isNextDisabled =
    newDeal.deal_source.length === 0 ||
    selectedProductOptions.length === 0 ||
    dealAmount === undefined;

  return (
    <WizardSectionCustomer
      serviceName={translate("BUSINESS_DETAILS")}
      titleBreadCrumb={translate("DEAL_INFORMATION")}
      nextLabel="COMPLETE_REGISTRATION"
      backLabel={
        !potentialDeals.length
          ? "BACK_TO_CONTACT_DETAILS"
          : "BACK_TO_POTENTIAL_DEALS"
      }
      isNextDisabled={isNextDisabled || creatingOrUpdatingDeal}
      isNextHidden={isNextDisabled}
      onBeforeNext={() => postBusinessDetails()}
      disabledBackMessage={disabledBackMessage()}
      subtitles={[
        translate("DEAL_BUSINESS_SUBTITLE", [newCustomer.CompanyName]),
      ]}
      loadingStatus={
        creatingOrUpdatingDeal || searchContactLoading
          ? LoadingStatusEnum.PENDING
          : LoadingStatusEnum.IDLE
      }
    >
      <Row className="minHeight-100">
        <Col className="mb-sm" lg={4}>
          <label className="fw-semibold">
            {translate("SOURCE_OF_REFFERENCE")} <b>*</b>
          </label>
          <Dropdown
            value={newDeal.deal_source}
            placeholder={translate("DROPDOWN_PLACEHOLDER")}
            options={dealSourceOptions}
            disabled={
              creatingOrUpdatingDeal || searchContactLoading || !!dealToUpdate
            }
            onChange={(value) => setNewDeal({ ...newDeal, deal_source: value })}
          />
        </Col>
        <Col className="mb-sm" lg={4}>
          <label className="fw-semibold">
            {translate("SERVICE")} <b>*</b>
          </label>
          <DropdownV9
            placeholder={translate("SELECT_LIST_MULTIPLE")}
            disabled={creatingOrUpdatingDeal || searchContactLoading}
            multiselect
            onOptionSelect={(_, { optionValue }, isSelected) => {
              if (optionValue) {
                const filteredProductIds = selectedProductOptions.find(
                  (option) => option === optionValue
                )
                  ? selectedProductOptions.filter(
                      (option) => option !== optionValue
                    )
                  : [...selectedProductOptions, optionValue as string];
                const item = productOptionsByArea.find(
                  (option) => option.value === optionValue
                );
                if (item) {
                  const addOrSubtract = isSelected
                    ? Number(item.data.price)
                    : -Number(item.data.price);
                  setDealAmount(Number(dealAmount || 0) + addOrSubtract);
                }
                setProductOptions(filteredProductIds);
              }
            }}
            selectedOptions={selectedProductOptions}
            options={productOptionsByArea}
          />
        </Col>
        <Col className="mb-sm" lg={4}>
          <label className="fw-semibold">
            {translate("ESTIMATE_BUSINESS_VALUE")} <b>*</b>
          </label>
          <Input
            data-testid="deal-amount"
            placeholder={translate("ENTER_AMOUNT_PLACEHOLDER")}
            type="number"
            required
            units="SEK"
            pattern={"\\d{2,}"}
            value={dealAmount}
            disabled={creatingOrUpdatingDeal || searchContactLoading}
            onChange={(e) => setDealAmount(parseInt(e.target.value || "0", 10))}
          />
        </Col>
      </Row>
    </WizardSectionCustomer>
  );
}
