import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { useAppDispatch } from "state/use-app-redux";
import { CustomersAPI } from "api/customers";
import { Dropdown } from "components/dropdown";
import { OverlaySpinner } from "components/spinner";
import VerticalStepper from "components/verticalStepper";
import { useTranslation } from "hooks/use-translate";
import { isValidOrganizationNumberOrSSN } from "libs/is-valid-organization-number-or-ssn";
import { isValidSwedishZipcode } from "libs/is-valid-zipcode";
import { getDigitsFromString, normalizeOrgNrAndSSN } from "libs/number-format";
import { removeWhiteSpace } from "libs/remove-whitespace-from-strings";
import {
  MAX_CHAR_ADDRESS,
  MAX_CHAR_CITY,
  MAX_CHAR_COMPANY_NAME,
  MAX_CHAR_FULL_NAME,
} from "constants/maxCharConsts";
import { CreateCustomer, Customer, CustomerTypes } from "models/customer";
import { LegalFormCodes, ValidLegalFormCodes } from "models/offer/Company";
import { useGetCompanyTypesQuery } from "services/ludvigApi";
import { RHFInput } from "components/input/RHFInput";
import { setDealsByOrgNumber } from "state/sales/actions";
import { RootState } from "state";
import { authMethod } from "auth";
import { plainToClass } from "class-transformer";
import { DealCustomer } from "models/offer/Customer";
import { createOrUpdateCustomer } from "state/offer/companyThunks";
import { CreateCustomerContext } from "../CreateCustomerContext";
import WizardSectionCustomer from "../components/WizardSectionCustomer";
import CustomerTypeRemoveModal from "../components/CustomerTypeRemoveModal";

function CustomerDetailsPage() {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    newCustomer,
    updateNewCustomer,
    toggleBusinessArea: selectBusinessArea,
  } = useContext(CreateCustomerContext);

  const [isReseted, setReset] = useState(false);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const [existingCustomer, setExistingCustomer] = useState<Customer>();
  const [orgNumber, setOrgNumber] = useState("");
  const { data: companyTypeList } = useGetCompanyTypesQuery();

  const currentDeal = useSelector(
    (state: RootState) => state.sales.currentDeal
  );

  // used for create/update customer
  const loadingStatus = useSelector(
    (state: RootState) => state.offers.currentOffer.status
  );

  const [isNewCustomerDataPrepopulated, setIsNewCustomerDataPrepopulated] =
    useState(false);

  const [isChangeCustomerTypeDialogOpen, setChangeCustomerTypeDialogOpen] =
    useState(false);

  const deals = useSelector((state: RootState) => state.sales);
  const dealsBusinessAreas = deals.dealOptions.data.find(
    (dealOption) => dealOption.name === "affarsomrade_deal_"
  );
  const {
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm<Partial<CreateCustomer>>({
    mode: "onTouched",
  });

  const resetFlowData = () => {
    updateNewCustomer({
      CompanyForm: undefined,
      CompanyRegistrationNumber: "",
      CompanyName: "",
      PostalDistrict: "",
      ZipCode: "",
      PostalAddress: "",
    });
    reset(newCustomer, { keepErrors: true });
  };

  useEffect(() => {
    dispatch(setDealsByOrgNumber([]));
  }, [dispatch]);

  useEffect(() => {
    if (!isReseted && !currentDeal) {
      reset(newCustomer, { keepErrors: true });
    }
    setReset(true);
  }, [reset, newCustomer, isReseted, currentDeal]);

  useEffect(() => {
    if (currentDeal && !isNewCustomerDataPrepopulated) {
      const dealBusinessArea = dealsBusinessAreas?.options.find(
        // eslint-disable-next-line no-underscore-dangle
        (businessArea) => businessArea.value === currentDeal.affarsomrade_deal_
      )?.label;
      const prepopulatedData: Partial<CreateCustomer> = {
        CompanyForm:
          currentDeal.organisationsnummer && currentDeal.company
            ? ""
            : LegalFormCodes.PrivatePerson,
        CompanyRegistrationNumber: currentDeal.organisationsnummer,
        CompanyName:
          currentDeal.organisationsnummer && currentDeal.company
            ? currentDeal.company
            : `${currentDeal.contact.firstname} ${currentDeal.contact.lastname}`,
        PostalDistrict: currentDeal.postal_city,
        ZipCode: currentDeal.postnummer,
        PostalAddress: currentDeal.postal_address,
        FirstName: currentDeal.contact.firstname,
        LastName: currentDeal.contact.lastname,
        ElectronicMailaddress: currentDeal.contact.email,
        Telephone: currentDeal.contact.phone,
        Mobile: currentDeal.contact.mobilephone,
      };
      if (dealBusinessArea) {
        selectBusinessArea(dealBusinessArea);
        updateNewCustomer(prepopulatedData);
      }
      reset(prepopulatedData);

      setIsNewCustomerDataPrepopulated(true);
      trigger();
    }
  }, [
    currentDeal,
    dealsBusinessAreas?.options,
    setIsNewCustomerDataPrepopulated,
    selectBusinessArea,
    trigger,
    updateNewCustomer,
    isNewCustomerDataPrepopulated,
    reset,
  ]);

  if (!newCustomer) {
    return <>Missing new customer</>;
  }
  if (!companyTypeList) {
    return <OverlaySpinner />;
  }
  const isExistingCustomer = async (newOrgNumber: string) => {
    if (newOrgNumber) {
      if (newOrgNumber === orgNumber) {
        return existingCustomer;
      }
      try {
        setIsLoadingCustomer(true);
        setOrgNumber(newOrgNumber);
        const token = await authMethod.getStoredAccessToken();
        const customer = await CustomersAPI.fetchCustomerById(
          token,
          newOrgNumber
        );

        setExistingCustomer(customer);
        return customer;
      } catch (e) {
        setExistingCustomer(undefined);
        return false;
      } finally {
        setIsLoadingCustomer(false);
      }
    }
  };

  const handleOnBeforeNext = async () => {
    const formData = getValues();
    updateNewCustomer({ ...newCustomer, ...formData });
    await dispatch(
      createOrUpdateCustomer(
        plainToClass(DealCustomer, {
          address: newCustomer.PostalAddress,
          postal_code: removeWhiteSpace(newCustomer.ZipCode),
          postal_town: newCustomer.PostalDistrict,
          org_number: newCustomer.CompanyRegistrationNumber,
          customer_name: newCustomer.CompanyName,
          legal_form: newCustomer.CompanyForm,
        })
      )
    );
    return true;
  };

  const addressValidation = register("PostalAddress", {
    required: translate("ADDRESS_REQUIRED"),
  });

  const postalTownValidation = register("PostalDistrict", {
    required: translate("POSTAL_TOWN_REQUIRED"),
  });

  const postalCodeValidation = register("ZipCode", {
    required: translate("POSTAL_CODE_REQUIRED"),
    validate: {
      isValid: (postalCode) => {
        if (!postalCode) {
          return translate("POSTAL_CODE_REQUIRED");
        }
        return isValidSwedishZipcode(postalCode)
          ? true
          : translate("POSTAL_CODE_INVALID");
      },
    },
  });

  const organizationNumberValidation = register("CompanyRegistrationNumber", {
    required:
      newCustomer.CompanyForm === "pp"
        ? translate("SOCIAL_SECURITY_NUMBER_REQUIRED")
        : translate("ORGANIZATION_NUMBER_REQUIRED"),
    validate: {
      isValid: async (number) => {
        if (!number) {
          return true;
        }

        if (
          (number.length !== 10 && number.length !== 12) ||
          !isValidOrganizationNumberOrSSN(number, true)
        ) {
          return translate("INVALID_ORGNR_OR_SSN_MESSAGE");
        }

        const normalizedNumber = normalizeOrgNrAndSSN(number);
        const customer = await isExistingCustomer(normalizedNumber);
        if (customer) {
          return translate("CUSTOMER_EXIST_MACONOMY", [
            customer.customer_number,
          ]);
        }
      },
    },
  });

  const companyNameValidation = register("CompanyName", {
    required:
      newCustomer.CompanyForm !== "pp"
        ? translate("COMPANY_NAME_REQUIRED")
        : translate("CUSTOMER_NAME_REQUIRED"),
  });

  const resetCustomerType = () => {
    if (newCustomer.CompanyForm !== undefined) {
      updateNewCustomer({
        CompanyForm: undefined,
        CompanyRegistrationNumber: "",
        CompanyName: "",
        PostalDistrict: "",
        ZipCode: "",
        PostalAddress: "",
      });
      reset({
        CompanyForm: "",
        CompanyRegistrationNumber: "",
        CompanyName: "",
        PostalDistrict: "",
        ZipCode: "",
        PostalAddress: "",
      });
    }
  };

  const renderCustomerTypeForm = () => {
    const companyTypeInput = register("CompanyForm", {
      validate: (value) =>
        companyTypeList
          .map((cti) => cti.WKName)
          .includes(value as ValidLegalFormCodes) || "invalid",
    });

    const companyTypeOptions = [
      ...companyTypeList.map((item) => ({
        value: item.WKName,
        label: item.NameSE,
      })),
    ];

    return (
      <Row className="mb-md pr-lg">
        <Col md={4}>
          <label className="fw-semibold">{translate("CUSTOMER_TYPE")}</label>
          <Dropdown
            formRegister={companyTypeInput}
            options={companyTypeOptions}
            placeholder={translate("SELECT_CUSTOMER_TYPE")}
            cancelChoice={() => {
              if (currentDeal) {
                setChangeCustomerTypeDialogOpen(true);
              } else {
                resetCustomerType();
              }
            }}
            onChange={(e) => {
              const legalForm = e;
              setValue("CompanyForm", legalForm as ValidLegalFormCodes, {
                shouldValidate: true,
              });
              updateNewCustomer({
                CompanyForm: legalForm,
              });
            }}
            value={newCustomer.CompanyForm}
          />
        </Col>
      </Row>
    );
  };

  const renderCompanyForm = () => {
    return (
      <>
        <Row className="mb-md pt-md">
          <Col md={4}>
            <label
              className="fw-semibold"
              htmlFor={organizationNumberValidation.name}
            >
              {newCustomer.CompanyForm !== "pp"
                ? translate("ORG_NUM")
                : translate("SSN")}{" "}
              *
            </label>
            <RHFInput
              register={organizationNumberValidation}
              title={`${translate("USE_FORMAT")} ${translate(
                "ORGNR_OR_SSN_PLACEHOLDER"
              )}`}
              placeholder={
                newCustomer.CompanyForm !== "pp"
                  ? translate("ORGANIZATION_NUMBER")
                  : translate("SSN")
              }
              className="customer-details-input"
              onChange={(e) => {
                setValue(
                  "CompanyRegistrationNumber",
                  getDigitsFromString(e.target.value),
                  {
                    shouldValidate: true,
                  }
                );
                if (newCustomer.CompanyForm === "pp") {
                  updateNewCustomer({
                    SignerSsn: getDigitsFromString(e.target.value),
                  });
                } else {
                  updateNewCustomer({
                    CompanyRegistrationNumber: getDigitsFromString(
                      e.target.value
                    ),
                  });
                }
              }}
              trigger={trigger}
              errorMessage={errors.CompanyRegistrationNumber?.message}
            />
          </Col>

          <Col md={4}>
            <label className="fw-semibold" htmlFor={companyNameValidation.name}>
              {newCustomer.CompanyForm !== "pp"
                ? translate("COMPANY_NAME")
                : translate("CUSTOMER_NAME")}{" "}
              *
            </label>
            <RHFInput
              placeholder={translate(
                newCustomer.CompanyForm === "pp"
                  ? "CUSTOMER_NAME"
                  : "COMPANY_NAME"
              )}
              className="customer-details-input"
              maxChar={
                newCustomer.CompanyForm !== "pp"
                  ? MAX_CHAR_COMPANY_NAME
                  : MAX_CHAR_FULL_NAME
              }
              value={getValues("CompanyName")}
              onChange={(e) => {
                setValue("CompanyName", e.target.value, {
                  shouldValidate: true,
                });
                updateNewCustomer({ CompanyName: e.target.value });
              }}
              register={companyNameValidation}
              trigger={trigger}
              errorMessage={errors.CompanyName?.message}
              disabled={isLoadingCustomer}
            />
          </Col>
          <Col md={4}>
            <label className="fw-semibold" htmlFor={postalTownValidation.name}>
              {translate("CITY")} *
            </label>
            <RHFInput
              pattern=".{2,}"
              placeholder={translate("INSERT_CITY")}
              className="customer-details-input"
              maxChar={MAX_CHAR_CITY}
              value={getValues("PostalDistrict")}
              onChange={(e) => {
                setValue("PostalDistrict", e.target.value, {
                  shouldValidate: true,
                });
                updateNewCustomer({ PostalDistrict: e.target.value });
              }}
              register={postalTownValidation}
              disabled={isLoadingCustomer}
              trigger={trigger}
              errorMessage={errors.PostalDistrict?.message}
            />
          </Col>
        </Row>
        <Row className="mb-md pt-md">
          <Col md={4}>
            <label className="fw-semibold" htmlFor={postalCodeValidation.name}>
              {translate("ZIP")} *
            </label>
            <RHFInput
              placeholder={translate("ZIP")}
              className="customer-details-input"
              onChange={(e) => {
                setValue("ZipCode", getDigitsFromString(e.target.value), {
                  shouldValidate: true,
                });
                updateNewCustomer({
                  ZipCode: removeWhiteSpace(e.target.value),
                });
              }}
              register={postalCodeValidation}
              disabled={isLoadingCustomer}
              trigger={trigger}
              errorMessage={errors.ZipCode?.message}
            />
          </Col>
          <Col md={4}>
            <label className="fw-semibold" htmlFor={addressValidation.name}>
              {translate("ADDRESS")} *
            </label>
            <RHFInput
              placeholder={translate("ADDRESS")}
              className="customer-details-input"
              register={addressValidation}
              maxChar={MAX_CHAR_ADDRESS}
              value={getValues("PostalAddress")}
              onChange={(e) => {
                setValue("PostalAddress", e.target.value, {
                  shouldValidate: true,
                });
                updateNewCustomer({ PostalAddress: e.target.value });
              }}
              disabled={isLoadingCustomer}
              trigger={trigger}
              errorMessage={errors.PostalAddress?.message}
            />
          </Col>
        </Row>
      </>
    );
  };

  const customerSteps = [
    {
      key: "1",
      label: translate("CUSTOMER_TYPE"),
      circleLabel: "A",
      component: renderCustomerTypeForm(),
      state: {
        active: !getValues("CompanyForm"),
        disabled: false,
        highlighted: !getValues("CompanyForm"),
      },
    },
    {
      key: "2",
      label: translate("CUSTOMER_DETAILS"),
      circleLabel: "B",
      component: getValues("CompanyForm") ? (
        renderCompanyForm()
      ) : (
        <span className="text-gray fs-italic p-sm">
          {translate("CUSTOMER_TYPE_INFO")}
        </span>
      ),
      state: {
        active: !!getValues("CompanyForm"),
        disabled: !getValues("CompanyForm"),
        highlighted: !!getValues("CompanyForm"),
      },
    },
  ];

  const isNextDisabled =
    !isValid ||
    isLoadingCustomer ||
    Object.keys(errors).length > 0 ||
    loadingStatus === "pending";

  const handleCustomerTypeChangeConfirm = () => {
    resetCustomerType();
    setChangeCustomerTypeDialogOpen(false);
  };

  const handleCustomerTypeChangeCancel = () => {
    setChangeCustomerTypeDialogOpen(false);
  };

  return (
    <>
      <WizardSectionCustomer
        serviceName={translate("OFFER_CUSTOMERINFO")}
        titleBreadCrumb={
          newCustomer.Type === CustomerTypes.PRIVATE_PERSON
            ? translate("CONTACT_INFORMATION")
            : translate("CUSTOMER_DETAILS")
        }
        onPrevious={resetFlowData}
        isNextDisabled={isNextDisabled}
        onBeforeNext={handleOnBeforeNext}
        subtitles={[translate("REGISTER_COMPANY_INFO")]}
        backLabel="GO_TO_BUSINESS_AREA"
        nextLabel="ADD_CONTACT_DETAILS"
        loadingStatus={loadingStatus}
      >
        <Col md={12} className="mt-sm">
          <VerticalStepper steps={customerSteps} />
        </Col>
      </WizardSectionCustomer>
      {isChangeCustomerTypeDialogOpen && (
        <CustomerTypeRemoveModal
          isOpen={isChangeCustomerTypeDialogOpen}
          onDiscard={handleCustomerTypeChangeCancel}
          onCancel={handleCustomerTypeChangeCancel}
          onSave={handleCustomerTypeChangeConfirm}
        />
      )}
    </>
  );
}

export default CustomerDetailsPage;
