import { Col, Row } from "react-bootstrap";
import { Checkmark20Regular } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import { ServiceInstance } from "models/activities/activity";
import TooltipV9 from "components/tooltip/TooltipV9";
import { TranslationKey } from "i18n";
import { getMonthName } from "libs/date/month-name";
import "./ServiceBox.scss";

type ServiceBoxCompletedProps = {
  servicesList: ServiceInstance[];
};

export function ServiceBoxCompleted({
  servicesList,
}: ServiceBoxCompletedProps) {
  const { translate, language } = useTranslation();

  return (
    <div className="service-box completed-collapsed">
      <Row className="service-box-content h-100">
        <Col className="my-xxs mx-0">
          <Row>
            <div className="d-flex align-items-center">
              <TooltipV9
                hasJSXContent
                content={
                  <div className="flex-column-tooltip-content">
                    {servicesList.map((service) => (
                      <span key={service.service_box_id}>
                        {service.getTitleWithWeek(language)}
                      </span>
                    ))}
                  </div>
                }
              >
                <p className="mr-sm m-0 fw-600 services-number">
                  {servicesList.length}
                </p>
              </TooltipV9>
              <p className="me-auto m-0 fw-600">
                {translate("COMPLETED_SERVICES_CURRENT_MONTH", [
                  translate(
                    getMonthName(
                      servicesList[0].month
                    ).toUpperCase() as TranslationKey
                  ),
                ])}
              </p>
              <p className="m-0 p-0 fw-semibold">
                <Checkmark20Regular
                  data-testid="completed-check"
                  className="ml-sm"
                />
              </p>
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
