import { Spinner } from "@fluentui/react-components";
import {
  Add16Regular,
  ArrowMaximize20Regular,
  ArrowMinimize20Regular,
  ChevronLeft20Regular,
  ChevronRight20Regular,
} from "@fluentui/react-icons";
import { addMonths, endOfMonth, startOfMonth } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "components/button";
import NotificationModal from "components/modal/NotificationModal";
import { SpinnerSize } from "components/spinner";
import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import { capitalize } from "libs/capitalize";
import { isCurrentMonth } from "libs/date/date-format";
import { generateMonths } from "libs/date/generate-month";
import { ServiceInstance } from "models/activities/activity";
import { Customer } from "models/customer";
import { useLazyGetOnedriveShortcutQuery } from "services/ludvigApi";
import { RootState } from "state";
import {
  ActivitiesTimelineWindow,
  fetchActivitiesServicesForCustomers,
  setActivitiesTimelineWindow,
} from "state/activities/actions";
import { AppDispatch } from "state/use-app-redux";
import { CustomerRowServices } from "./CustomerRowServices";

type CustomersServicesTableProps = {
  filteredCustomers: Customer[];
  isFiltering: boolean;
  serviceFilter: (customerNumber: string) => ServiceInstance[];
  openCustomerPanel: (customer: Customer) => void;
  incompleteFilterEnabled?: boolean;
  isCollapsedView?: boolean;
};

export function CustomersServicesTable({
  filteredCustomers,
  isFiltering,
  serviceFilter,
  openCustomerPanel,
  incompleteFilterEnabled = false,
  isCollapsedView = false,
}: CustomersServicesTableProps) {
  const dispatch: AppDispatch = useDispatch();
  const customers = useSelector((state: RootState) => state.customers);
  const activities = useSelector((state: RootState) => state.activities);
  const { timelineWindow } = activities;
  const { translate, language } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [timeline, setTimeline] = useState<ActivitiesTimelineWindow>();
  const [isOpenOnedriveModal, setIsOpenOnedriveModal] = useState(false);
  const isIconVisible = isCollapsedView ? "hidden" : "visible";
  const [trigger] = useLazyGetOnedriveShortcutQuery();

  const moveTimeWindow = async (addTimelineMonths: number) => {
    const previousFirst = startOfMonth(timelineWindow[0]);
    const previousLast = endOfMonth(timelineWindow[timelineWindow.length - 1]);

    const newFirst = addMonths(previousFirst, addTimelineMonths);
    const newLast = addMonths(previousLast, addTimelineMonths);

    const months = generateMonths(newFirst, newLast);

    const newTimeline = await dispatch(
      setActivitiesTimelineWindow({ months, fetchActivities: false })
    ).unwrap();

    setTimeline(newTimeline);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      if (timeline) {
        dispatch(fetchActivitiesServicesForCustomers(timeline));
      }
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [timeline, dispatch]);

  if (customers.data.length === 0) {
    return null;
  }

  const displayFilteredCustomers = filteredCustomers.map((c) => {
    const customerServices = serviceFilter(c.customer_number);

    return isCollapsedView ? (
      customerServices.length > 0 && (
        <CustomerRowServices
          key={c.customer_number}
          isFiltering={isFiltering}
          expanded={expanded}
          customer={c}
          filteredServices={customerServices}
          openPanel={openCustomerPanel}
          showCompleted={!incompleteFilterEnabled}
          showCollapsedView={isCollapsedView}
        />
      )
    ) : (
      <CustomerRowServices
        key={c.customer_number}
        isFiltering={isFiltering}
        expanded={expanded}
        customer={c}
        filteredServices={customerServices}
        openPanel={openCustomerPanel}
        showCompleted={!incompleteFilterEnabled}
        showCollapsedView={isCollapsedView}
      />
    );
  });

  const handleClickOnedriveShortcut = () => {
    trigger(null);
    setIsOpenOnedriveModal(true);
  };

  return (
    <div className="customer-services-table--container min-width-1400 overflow-y-hidden">
      <Col className="customer-services-table--sticky" md={12}>
        <Row className="fw-bold my-lg min-width-1400 d-flex align-items-center contentHeader">
          <Col md={10} className="d-flex pb-sm">
            <div className="header-title pr-xxs">
              <h3 className="pl-sm stringLength no-margin">
                {translate("PROJECT_OVERVIEW")}
              </h3>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <div
                className={`pr-sm ml-sm ${
                  !isCollapsedView && "vertical-divider"
                }`}
              />
            </div>
            <div className="pl-sm d-flex">
              <TooltipV9
                positioning="above"
                content={expanded ? "SHOW_MINIMAL_VIEW" : "SHOW_EXPANDED_VIEW"}
              >
                {expanded ? (
                  <ArrowMaximize20Regular
                    visibility={isIconVisible}
                    className="blue-link mt-sm fpx-16"
                    onClick={() => setExpanded(!expanded)}
                  />
                ) : (
                  <ArrowMinimize20Regular
                    visibility={isIconVisible}
                    className="blue-link mt-sm fpx-16"
                    onClick={() => setExpanded(!expanded)}
                  />
                )}
              </TooltipV9>
              {(activities.isLoading || customers.isLoading) && (
                <Spinner className="ml-sm" size={SpinnerSize.ExtraSmall} />
              )}
            </div>
          </Col>
          <Col md={2} className="ml-0 d-flex justify-content-end">
            <span className="cursor-pointer">
              <Button
                onClick={handleClickOnedriveShortcut}
                variant="outline-primary"
              >
                <Add16Regular className="mr-sm" />
                {translate("ONEDRIVE_SHORTCUT")}
              </Button>
            </span>
          </Col>
        </Row>
        <Row className="px-lg py-0">
          <Col md={2} className="p-sm min-width-300">
            <div className="bg-gray-300 h-100 w-100 p-sm fw-600 text-center border-radius-4">
              {translate("CUSTOMER_DETAILS")}
            </div>
          </Col>
          <Col>
            <Row className="d-flex justify-content-between align-items-center pl-xs transform-x-1">
              {timelineWindow.map((timelineDate, i) => (
                <Col
                  key={timelineDate.toString()}
                  md={3}
                  className="d-flex justify-content-center pl-0 pr-sm pt-sm"
                >
                  <div
                    className={`d-flex justify-content-between h-100 w-100 p-sm fw-600 border-radius-4 ${
                      isCurrentMonth(timelineDate)
                        ? "bg-currentMonthColumn"
                        : "bg-gray-300"
                    }`}
                  >
                    {i === 0 && timelineWindow.length > 1 && (
                      <TooltipV9
                        positioning="above-start"
                        content="ACTIVITIES_LOADING"
                        enabled={!!activities.isLoading}
                      >
                        <ChevronLeft20Regular
                          className={`cursor-pointer blue-link ${
                            activities.isLoading && "disabled"
                          }`}
                          onClick={() => moveTimeWindow(-1)}
                        />
                      </TooltipV9>
                    )}
                    <span className="m-auto">
                      {capitalize(
                        timelineDate.toLocaleDateString(language, {
                          month: "long",
                          year: "numeric",
                        })
                      )}
                    </span>
                    {i === timelineWindow.length - 1 &&
                      timelineWindow.length > 1 && (
                        <TooltipV9
                          positioning="above-end"
                          content="ACTIVITIES_LOADING"
                          enabled={!!activities.isLoading}
                        >
                          <ChevronRight20Regular
                            className={`cursor-pointer blue-link ${
                              activities.isLoading && "disabled"
                            }`}
                            onClick={() => moveTimeWindow(1)}
                          />
                        </TooltipV9>
                      )}
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="p-lg py-0">
        <Row md={12}>
          <Col>
            {displayFilteredCustomers}
            {filteredCustomers.length === 0 && (
              <Row>
                <Col colSpan={4}>{translate("NO_SEARCH_RESULTS")}</Col>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
      {isOpenOnedriveModal && (
        <NotificationModal
          isOpen={isOpenOnedriveModal}
          onCancel={() => {
            setIsOpenOnedriveModal(false);
          }}
          header={translate("ONEDRIVE_SHORTCUT")}
          body={
            <>
              {translate("ONEDRIVE_SHORTCUT_CREATING_MESSAGE")}
              <br />
              <br />
              <a
                href="https://intranet.ludvig.se/organisation/it-och-utveckling/it/lagra-filer/"
                target="_blank"
                rel="noreferrer"
              >
                {translate("ONEDRIVE_SHORTCUT_CREATING_MESSAGE_LINK")}
              </a>
            </>
          }
        />
      )}
    </div>
  );
}
