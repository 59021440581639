import { useCallback, useEffect, useRef, useState } from "react";
import {
  IDynamicPerson,
  PeoplePicker,
  PeoplePickerProps,
  UserType,
  ViewType,
} from "@microsoft/mgt-react";
import {
  ArrowClockwise20Regular,
  ChevronLeft20Regular,
} from "@fluentui/react-icons";
import { useDispatch } from "react-redux";
import { Spinner } from "@fluentui/react-components";

import { useTranslation } from "hooks";
import { Debounce } from "libs/debounce";
import { uuidv4 } from "libs/uuid";
import { appendToastMessage } from "state/notifications";
import { AppDispatch } from "state/use-app-redux";
import { UsersAPI } from "api/users";
import { PersonDetails } from "models/profile";
import { UserSnapshot } from "models/user";
import TooltipV9 from "components/tooltip/TooltipV9";
import { SpinnerSize } from "components/spinner";
import { Persona } from "./Persona";
import { CircleIconOptions, CircleIconWrapper } from "./CircleIconWrapper";
import "./PersonPicker.scss";

type Props = {
  placeholder?: string;
  selectionMode: "single" | "multi";
  selectedUsers: UserSnapshot[];
  onPersonChange: (
    email: string,
    isSingleSelection: boolean,
    selected?: PersonDetails
  ) => void;
  emptyPlaceholder?: string;
  removeSelectedUser?: (param: UserSnapshot[]) => void;
  label?: string;
  maxShownUsers?: number;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  required?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onReset?: () => void;
  userFilters?: string;
  excludeMe?: boolean;
} & Omit<PeoplePickerProps, "userFilters">;

type CustomElement = HTMLElement & {
  userInput: string;
};

export function PersonPicker({
  placeholder,
  selectionMode,
  selectedUsers,
  onPersonChange,
  emptyPlaceholder,
  removeSelectedUser,
  label,
  maxShownUsers = 3,
  disabled = false,
  className = "",
  isLoading = false,
  required = false,
  hasError = false,
  errorMessage = "",
  onReset,
  defaultSelectedUserIds,
  userFilters,
  excludeMe,
  ...props
}: Props) {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [uniqueId] = useState(uuidv4());

  const [showPersonSearch, setPersonSearch] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  const pickerRef = useRef<HTMLDivElement>(null);
  const peoplePickerRef = useRef<HTMLDivElement>(null);
  const [defaultPeople, setDefaultPeople] = useState<IDynamicPerson[]>();
  const [people, setPeople] = useState<IDynamicPerson[]>();

  const hasSelectedUsers = selectedUsers.length > 0;
  const isSingleSelection = selectionMode === "single";
  const hasResetOption =
    hasSelectedUsers && !isSingleSelection && !disabled && onReset;
  const defaultEmptyPlaceholder = translate(
    isSingleSelection
      ? "PERSON_PICKER.SELECT_SINGLE_USER"
      : "PERSON_PICKER.SELECT_USERS"
  );

  const renderSelectedUsers = () => {
    if (isExpanded) {
      return selectedUsers;
    }
    // Show only first 3 selected users
    const visibleUsers = selectedUsers.slice(0, maxShownUsers);
    return visibleUsers;
  };

  // Close search on outside click
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setPersonSearch(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pickerRef]);

  const getDefaultPeople = async () => {
    if (!defaultPeople) {
      try {
        let users = [];
        if (userFilters) {
          users = await UsersAPI.getDefaultFilteredUsers(
            userFilters,
            excludeMe
          );
        } else {
          users = await UsersAPI.getDefaultPeopleForPersonPicker(excludeMe);
        }
        setDefaultPeople(users);
        setPeople(users);
      } catch (err) {
        dispatch(appendToastMessage("MGT_PEOPLE_PICKER_ERROR", "error"));
      }
    }
    setPersonSearch(!showPersonSearch);
  };

  // Search auto focus
  useEffect(() => {
    if (showPersonSearch && peoplePickerRef.current) {
      peoplePickerRef.current.focus();
    }
    if (!showPersonSearch) {
      setPeople(defaultPeople);
    }
  }, [showPersonSearch, defaultPeople]);

  const onSelectionChanged = (e: Event) => {
    const custom = e as CustomEvent;
    if (!custom || !custom.detail[0]) {
      return;
    }

    const person = custom.detail[0];

    setPersonSearch(false);
    onPersonChange(
      person.email,
      isSingleSelection,
      custom.detail[0] as PersonDetails
    );
  };

  const DEBOUNCE_DELAY = 500;
  const debounceUpdate = new Debounce(DEBOUNCE_DELAY);

  const handleKeyUp = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      const target = e.target as CustomElement;
      const { userInput } = target;

      const debouncedFilterPeople = () => {
        debounceUpdate.fire(async () => {
          if (userInput === "") {
            setPeople(defaultPeople);
          } else {
            const users = await UsersAPI.getFilteredUsers(
              userInput,
              userFilters
            );
            setPeople(users);
          }
        });
      };

      // check if key pressed is printable character, backspace, or delete
      const isPrintableCharacter = /^[\x20-\x7E]$/.test(e.key);
      const isBackspace = e.key === "Backspace";
      const isDelete = e.key === "Delete";

      if (userInput === "") {
        setPeople(defaultPeople);
      } else if (isPrintableCharacter || isBackspace || isDelete) {
        debouncedFilterPeople();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultPeople, userFilters]
  );

  const renderIcon = () => {
    switch (true) {
      case hasSelectedUsers && !showPersonSearch && !isSingleSelection:
        return CircleIconOptions.add;
      case hasSelectedUsers && !showPersonSearch && isSingleSelection:
        return CircleIconOptions.edit;
      case showPersonSearch:
        return CircleIconOptions.close;
      default:
        return CircleIconOptions.default;
    }
  };

  return (
    <div className={`w-100 ${className}`}>
      {label && (
        <div className="d-flex align-items-end h-22">
          <label className="fw-semibold">{label}</label>
          {hasResetOption && (
            <TooltipV9 content="PERSON_PICKER.RESET_SELECTION">
              <ArrowClockwise20Regular
                className="ml-xs text-color-blue cursor-pointer"
                onClick={onReset}
              />
            </TooltipV9>
          )}
        </div>
      )}
      <div
        ref={pickerRef}
        className="position-relative d-flex align-items-center w-100 h-34"
      >
        {(!hasSelectedUsers || (hasSelectedUsers && !disabled)) && (
          <CircleIconWrapper
            iconName={renderIcon()}
            fadeInOnHover={!showPersonSearch}
            disabled={disabled}
            persist
            onClick={async () => {
              if (!disabled) {
                await getDefaultPeople();
              }
            }}
          />
        )}
        <div
          className={`people-picker-search ${
            showPersonSearch ? "show" : "hide"
          }`}
        >
          {showPersonSearch && (
            <PeoplePicker
              ref={peoplePickerRef}
              userType={UserType.user}
              selectionChanged={onSelectionChanged}
              placeholder={placeholder}
              defaultSelectedUserIds={defaultSelectedUserIds}
              selectionMode="single"
              // The next line is commented on purpose connected to ML-135
              // userFilters="not(startsWith(givenName, 'Konferensrum'))"
              people={people}
              onKeyUp={handleKeyUp}
              {...props}
            />
          )}
        </div>
        {!hasSelectedUsers && (
          <span
            className="body-italic-light wrapper-32 cursor-pointer w-100"
            onClick={async () => {
              if (!disabled) {
                await getDefaultPeople();
              }
            }}
          >
            {emptyPlaceholder || defaultEmptyPlaceholder}
          </span>
        )}
        <div
          className={`user-avatar-wrapper pointer-events-none${
            !isSingleSelection ? " multi-select" : ""
          }`}
        >
          {isLoading ? (
            <Spinner size={SpinnerSize.Tiny} className="py-sm px-lg" />
          ) : (
            renderSelectedUsers().map((selectedUser) =>
              removeSelectedUser ? (
                <TooltipV9
                  key={`${uniqueId}-${selectedUser.id}`}
                  content={selectedUser.name ?? ""}
                  hasJSXContent
                  isTextBold={disabled}
                  enabled={disabled}
                  notTranslatable
                >
                  <CircleIconWrapper
                    className={disabled ? "disabled" : ""}
                    fadeInOnHover={!disabled}
                    iconName={CircleIconOptions.remove}
                    personName={selectedUser.name}
                    disabled={disabled}
                    onClick={() => {
                      if (!disabled) {
                        removeSelectedUser(
                          selectedUsers.filter(
                            (users) => users.id !== selectedUser.id
                          )
                        );
                      }
                    }}
                  >
                    <Persona
                      className="mr-sm"
                      userId={selectedUser.id}
                      view={
                        isSingleSelection ? ViewType.oneline : ViewType.image
                      }
                    />
                  </CircleIconWrapper>
                </TooltipV9>
              ) : (
                <Persona
                  key={`${uniqueId}-${selectedUser.id}`}
                  className={`mr-sm${disabled ? " disabled" : ""}`}
                  userId={selectedUser.id}
                  view={isSingleSelection ? ViewType.oneline : ViewType.image}
                />
              )
            )
          )}
        </div>
        {selectedUsers.length > maxShownUsers && (
          <TooltipV9
            content={
              isExpanded
                ? "PERSON_PICKER.COLLAPSE_USER_LIST"
                : "PERSON_PICKER.EXPAND_USER_LIST"
            }
          >
            <div
              className="text-color-blue cursor-pointer ml-xs wrapper-32"
              onClick={() => setExpanded(!isExpanded)}
            >
              {isExpanded ? (
                <ChevronLeft20Regular />
              ) : (
                `+${selectedUsers.length - maxShownUsers}`
              )}
            </div>
          </TooltipV9>
        )}
      </div>
      {required && hasError && errorMessage !== "" && (
        <div className="error-message">
          <span className="text-color-red">
            <span>{errorMessage}</span>
          </span>
        </div>
      )}
    </div>
  );
}
