import { ArrowUpRight16Regular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";

import { Button } from "components/button";
import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import { dateLanguageFormatter } from "libs/date/date-format";
import { numberFormatOrZero } from "libs/number-format";
import { Customer } from "models/customer";
import { AppRouteHelper } from "routes";

type BalancesTableProps = {
  activePageObjects: Customer[];
  customerNumber?: string;
  isNotDetailedPage?: boolean;
};

export default function BalancesTable({
  activePageObjects,
  customerNumber,
  isNotDetailedPage = false,
}: BalancesTableProps) {
  const { translate, language } = useTranslation();
  const navigate = useNavigate();
  const dateFormat = dateLanguageFormatter(language, "yyyy-MM-dd");

  const URLProps = new URLSearchParams(window.location.search);
  const isConveyorBeltV2 = URLProps.get("conveyorBeltV2");

  return (
    <>
      <div className="p-lg horizontal-scroll cs-content contentBody">
        <table>
          <thead>
            <tr className="full-width px-md align-items-center my-sm fw-bold no-border">
              <td>
                <span>{translate("ASSIGNMENT")}</span>
              </td>
              <td>
                <span>{translate("ACCOUNTING_DATE")}</span>
              </td>
              <td>
                <span>{translate("DUE_DATE")}</span>
              </td>
              <td>
                <span>{translate("STATUS")}</span>
              </td>
              <td>
                <span>{translate("DEMAND_LEVEL")}</span>
              </td>
              <td>
                <span className="number-columns-alignment-right">
                  {translate("DEPT")}
                </span>
              </td>
              <td>
                <span className="number-columns-alignment-right">
                  {translate("CREDIT")}
                </span>
              </td>
              <td>
                <span className="number-columns-alignment-right">
                  {translate("REST")}
                </span>
              </td>
              <td>
                <span>{translate("INVOICE_NUMBER")}</span>
              </td>
              <td>
                <span>{translate("MARKING")}</span>
              </td>
            </tr>
          </thead>
          <tbody>
            {activePageObjects &&
              activePageObjects.map((customer: any, index) => {
                return (
                  <tr
                    className="full-width px-md py-sm align-items-center"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <td>
                      <span>{customer.jobnumber}</span>
                    </td>
                    <td>
                      <span>{dateFormat(new Date(customer.postingdate))}</span>
                    </td>
                    <td>
                      <span>{dateFormat(new Date(customer.duedate))}</span>
                    </td>
                    <td>
                      <span>
                        {customer.openclosed
                          ? translate("STATUS_CLOSED")
                          : translate("STATUS_OPEN")}
                      </span>
                    </td>
                    <td>
                      <span>{customer.reminderlevel}</span>
                    </td>
                    <td>
                      <span className="number-columns-alignment-right">
                        {numberFormatOrZero(customer.debitbase)}
                      </span>
                    </td>
                    <td>
                      <span className="number-columns-alignment-right">
                        {numberFormatOrZero(customer.creditbase)}
                      </span>
                    </td>
                    <td>
                      <span className="number-columns-alignment-right">
                        {numberFormatOrZero(customer.remainderbase)}
                      </span>
                    </td>
                    <td>
                      <span>{customer.transactionnumber}</span>
                    </td>
                    <td style={{ maxWidth: "150px" }}>
                      <TooltipV9
                        hasJSXContent
                        content={
                          <div className="d-flex flex-column">
                            {customer.comment2.trim() ? (
                              <>
                                <span>1. {customer.comment1}</span>
                                <span>2. {customer.comment2}</span>
                              </>
                            ) : (
                              <span>{customer.comment1}</span>
                            )}
                          </div>
                        }
                      >
                        <div
                          className="d-flex flex-column"
                          style={{ maxWidth: "150px" }}
                        >
                          {customer.comment2.trim() ? (
                            <>
                              <span className="stringLength">
                                1. {customer.comment1}
                              </span>
                              <span className="stringLength">
                                2. {customer.comment2}
                              </span>
                            </>
                          ) : (
                            <span className="stringLength">
                              {customer.comment1}
                            </span>
                          )}
                        </div>
                      </TooltipV9>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {isNotDetailedPage && (
        <div className="d-flex align-items-end my-md  ">
          <Button
            variant="outline-outline"
            className="justify-content-start primary-colored-text"
            onClick={() =>
              navigate({
                pathname: isConveyorBeltV2
                  ? AppRouteHelper.getCustomerBalancesInvoicesPaymentsPage(
                      customerNumber,
                      true
                    )
                  : AppRouteHelper.getCustomerBalancesInvoicesPaymentsPage(
                      customerNumber
                    ),
              })
            }
          >
            <ArrowUpRight16Regular />
            <span className="d-inline mx-sm fw-bold primary">
              {translate("GO_TO_BALANCES_INVOICES_PAYMENTS_LINK")}
            </span>
          </Button>
        </div>
      )}
    </>
  );
}
