/* eslint-disable no-nested-ternary */
import { ArrowSort20Regular, Filter20Regular } from "@fluentui/react-icons";
import { startOfMonth } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Spinner, Tab, TabList } from "@fluentui/react-components";
import ContextualMenuV9 from "components/contextualMenu/ContextualMenuV9";
import SearchInput from "components/input/search";
import {
  isCustomerWithRestrictedAccess,
  isUserTeamOwner,
} from "libs/customer-settings-helpers";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
  LayoutSubHeader,
  LayoutWrapper,
} from "components/layout/Layout";
import { SpinnerSize } from "components/spinner";
import { useStickyState, useStickyStateForDate } from "hooks/use-sticky-state";
import { useTranslation } from "hooks/use-translate";
import { renderIcon } from "libs/render-icon";
import {
  ActivityDefinition,
  ActivityStatus,
  Recurrence,
  ServiceTypes,
} from "models/activities/activity";
import { Customer } from "models/customer";
import { UserSnapshot } from "models/user";
import { RootState } from "state";
import {
  fetchServiceTypes,
  setActivitiesTimelineWindow,
  setTimelineWindow,
  setTimelineWindowFromLocalStorage,
} from "state/activities/actions";
import { fetchCustomers } from "state/customers/actions";
import { AppDispatch } from "state/use-app-redux";
import Checkbox from "components/checkbox";
import { useCustomerDocumentsCompleted } from "../components/sorting/use-completed-documents";
import { useCustomerServiceFilter } from "../components/filters/service-filters";
import { CustomerDetailsPanel } from "./components/CustomerDetailsPanel";
import { CustomersServicesTable } from "./components/CustomersServicesTable";
import { FilterSortBox, FilterSortList } from "./components/FilterSortBox";
import "./HeaderButtons.scss";

const RecurrenceTypes = Object.values(Recurrence);
const ServiceTypesList = Object.values(ServiceTypes);

export default function AssignmentManagement() {
  const FILTER_ACTIVITY_USERS_IDS = "localFilterUserIds";
  const FILTER_NEXT_ACTIVITY_USERS = "localFilterNextActivityUsers";
  const FILTER_ACTIVITY_SERVICE_TYPES = "localFilterServiceTypes";
  const FILTER_ACTIVITY_DEFINITIONS = "localFilterActivityDefinitions";
  const FILTER_ACTIVITY_FREQUENCIES = "localFilterActivityFrequencies";
  const FILTER_ACTIVITY_STATUSES = "localFilterActivityStatuses";
  const FILTER_INCOMPLETE_ENABLED = "localFilterIncompleteEnabled";
  const FILTER_SELECTED_DATE = "localFilterSelectedDate";
  const FILTER_OVERDUE_ENABLED = "localFilterOverdueEnabled";
  const FILTER_ACTIVITY_DEADLINE = "localFilterActivityDeadline";
  const SELECTED_TAB_VIEW = "localSelectedTabView";
  const SORT_HANDED_DOCS = "localSortHandedDocs";

  const dispatch: AppDispatch = useDispatch();
  const [selectedDate, setSelectedDate] =
    useStickyStateForDate(FILTER_SELECTED_DATE);
  const [overdueFilterEnabled, setEnabledOverdueFilter] =
    useStickyState<boolean>(FILTER_OVERDUE_ENABLED, false);
  const [incompleteFilterEnabled, setIncompleteFilterEnabled] =
    useStickyState<boolean>(FILTER_INCOMPLETE_ENABLED, false);

  const [selectedTabView, setSelectedTabView] = useStickyState<string>(
    SELECTED_TAB_VIEW,
    "all"
  );

  const [selectedActivityFilterFrequencies, setActivityFilterFrequencies] =
    useStickyState<Recurrence[]>(FILTER_ACTIVITY_FREQUENCIES, []);
  const [selectedActivityFilterDefinitions, setActivityFilterDefinitions] =
    useStickyState<ActivityDefinition[]>(FILTER_ACTIVITY_DEFINITIONS, []);
  const [selectedActivityFilterUserIds, setActivityFilterUserIds] =
    useStickyState<UserSnapshot[]>(FILTER_ACTIVITY_USERS_IDS, []);
  const [selectedNextActivityFilterUsers, setNextActivityFilterUsers] =
    useStickyState<UserSnapshot[]>(FILTER_NEXT_ACTIVITY_USERS, []);
  const [selectedActivityFilterServiceTypes, setActivityFilterServiceTypes] =
    useStickyState<string[]>(FILTER_ACTIVITY_SERVICE_TYPES, []);
  const [selectedActivityFilterStatuses, setActivityFilterStatuses] =
    useStickyState<ActivityStatus[]>(FILTER_ACTIVITY_STATUSES, []);

  const [selectedActivityFilterDeadline, setActivityFilterDeadline] =
    useStickyState<string | null>(FILTER_ACTIVITY_DEADLINE, null);
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);

  const { translate, ts, language } = useTranslation();

  const [showCustomerDetailsPanel, setShowCustomerDetailsPanel] =
    useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");

  const [customerSearchTerm, setCustomerSearchTerm] = useState("");
  const [isSearchDataLoading, setIsSearchDataLoading] = useState(false);
  const [isBackendSearchDataDisplayed, setIsBackendSearchDataDisplayed] =
    useState(false);
  const customers = useSelector((state: RootState) => state.customers);
  const activities = useSelector((state: RootState) => state.activities);
  const { timelineWindow, definitions } = activities;
  const { dataMap: definitionsMap } = definitions;
  const { currentUser } = useSelector((state: RootState) => state.users);

  const { servicesFilter, isFiltering } = useCustomerServiceFilter({
    filterOverdue: overdueFilterEnabled,
    filterActive: incompleteFilterEnabled,
    filterStatuses: selectedActivityFilterStatuses,
    filterRecurrence: selectedActivityFilterFrequencies,
    filterDefinitions: selectedActivityFilterDefinitions,
    filterUserIds: selectedActivityFilterUserIds,
    filterServiceTypes: selectedActivityFilterServiceTypes,
    filterByMonth: selectedDate,
    filterByDeadline: selectedActivityFilterDeadline,
    filterNextUserIds: selectedNextActivityFilterUsers,
    definitionsMap,
  });

  const openCustomerPanel = (customer: Customer) => {
    if (showCustomerDetailsPanel) {
      if (customer.customer_number === selectedCustomer) {
        setShowCustomerDetailsPanel(false);
        setSelectedCustomer("");
      } else {
        setSelectedCustomer(customer.customer_number);
      }
    } else {
      setShowCustomerDetailsPanel(true);
      setSelectedCustomer(customer.customer_number);
    }
  };

  useEffect(() => {
    // TODO: reset store if other version of CB was loaded first
    // should be removed after going live with CB2
    if (
      !localStorage.getItem("conveyorBeltVersion") ||
      localStorage.getItem("conveyorBeltVersion") === "2"
    ) {
      localStorage.setItem("conveyorBeltVersion", "1");
      dispatch(setActivitiesTimelineWindow({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchCustomers = useCallback(() => {
    setIsSearchDataLoading(true);
    setIsBackendSearchDataDisplayed(true);

    dispatch(fetchCustomers(customerSearchTerm)).then((res) => {
      setIsSearchDataLoading(false);
    });
  }, [customerSearchTerm, dispatch]);

  useEffect(() => {
    if (isBackendSearchDataDisplayed && customerSearchTerm === "") {
      searchCustomers();
      setIsBackendSearchDataDisplayed(false);
    }
  }, [
    customerSearchTerm,
    isBackendSearchDataDisplayed,
    searchCustomers,
    setIsBackendSearchDataDisplayed,
  ]);

  // deadline
  const deadlineOptions = [
    { value: "1", text: `1 ${ts("WORKING_DAY_FROM_NOW")}` },
    { value: "2", text: `2 ${ts("WORKING_DAYS_FROM_NOW")}` },
    { value: "3", text: `3 ${ts("WORKING_DAYS_FROM_NOW")}` },
    { value: "4", text: `4 ${ts("WORKING_DAYS_FROM_NOW")}` },
    { value: "5", text: `5 ${ts("WORKING_DAYS_FROM_NOW")}` },
  ];

  // frequency
  const frequencyOptions = RecurrenceTypes.filter(
    (recurrence) => recurrence !== Recurrence.Unknown
  ).map((recurrence) => ({
    value: recurrence,
    text: translate(recurrence),
  }));

  const serviceTypesOptions = ServiceTypesList.map((service) => ({
    value: service,
    text: translate(service),
  }));

  // statuses
  const statusOptions = [
    {
      value: "PASSED_DEADLINE",
      text: translate("PASSED_DEADLINE"),
    },
    {
      value: ActivityStatus.ManagerSupport,
      text: translate(ActivityStatus.ManagerSupport),
    },
    {
      value: ActivityStatus.SupportSentToClient,
      text: translate(ActivityStatus.SupportSentToClient),
    },
    {
      value: ActivityStatus.SupportNeeded,
      text: translate(ActivityStatus.SupportNeeded),
    },
    {
      value: ActivityStatus.SupportFinished,
      text: translate(ActivityStatus.SupportFinished),
    },
  ];

  // activity definition
  const activityTypeOptions = activities.definitions.data
    .filter(
      (definition, i, arr) =>
        arr.findIndex((d) => d.activity_name === definition.activity_name) === i
    )
    .map((definition) => ({
      text: definition.getTranslation(language),
      value: definition.title,
      data: definition,
    }))
    .sort((a, b) => (a.text < b.text ? -1 : 1));

  useEffect(() => {
    if (
      activities.data.length === 0 &&
      !activities.isLoading &&
      !activities.hasLoaded &&
      !activities.hasFailed &&
      !customers.isLoading
    ) {
      if (selectedDate !== null && selectedDate !== undefined) {
        dispatch(setTimelineWindow([selectedDate]));
        dispatch(setActivitiesTimelineWindow({ months: [selectedDate] }));
      } else {
        dispatch(setTimelineWindowFromLocalStorage());
      }
      dispatch(fetchServiceTypes());
    }
  }, [
    activities.hasFailed,
    activities.data.length,
    activities.hasLoaded,
    activities.isLoading,
    customers.isLoading,
    selectedDate,
    dispatch,
  ]);

  const resetTimeWindowComplete = () => {
    if (selectedDate !== undefined) {
      setSelectedDate(undefined);
      dispatch(setActivitiesTimelineWindow({}));
    }
  };

  const setTimeWindowAt = (date: Date | null | undefined) => {
    const newDate = date ? startOfMonth(date) : undefined;
    setSelectedDate(newDate);

    if (newDate !== null && newDate !== undefined) {
      dispatch(setActivitiesTimelineWindow({ months: [newDate] }));
    }
  };

  const filterCustomer = (customer: Customer) => {
    const filteredServices = servicesFilter(customer.customer_number);

    if (!filteredServices && isFiltering) {
      return false;
    }

    if (customerSearchTerm) {
      if (
        isBackendSearchDataDisplayed &&
        isCustomerWithRestrictedAccess(customer) &&
        !isUserTeamOwner(customer, currentUser)
      ) {
        return false;
      }
      return (
        customer.salesperson_email
          .toLowerCase()
          .includes(customerSearchTerm.toLowerCase()) ||
        customer.customer_number.includes(customerSearchTerm) ||
        customer.name
          .toLowerCase()
          .includes(customerSearchTerm.toLowerCase()) ||
        customer
          .getManagers()
          .some((m) => m.email?.toLowerCase().includes(customerSearchTerm))
      );
    }

    return true;
  };
  const [disableSort, setDisableSort] = useState(true);

  const [handedDocs, setHandedDocs] = useStickyState(SORT_HANDED_DOCS, false);

  useEffect(() => {
    if (
      Boolean(selectedDate) &&
      selectedActivityFilterServiceTypes.length > 0
    ) {
      setDisableSort(false);
    } else {
      setDisableSort(true);
    }
  }, [selectedDate, selectedActivityFilterServiceTypes, servicesFilter]);

  const allCustomers = customers.data.map((c) => c.customer);

  const filteredCustomers = allCustomers
    .filter(filterCustomer)
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  const customerServices = filteredCustomers.flatMap((fc) => {
    return {
      customer_number: fc.customer_number,
      servicesFilter: servicesFilter(fc.customer_number),
    };
  });

  const dataSorted = useCustomerDocumentsCompleted(
    timelineWindow,
    customerServices
  );

  const customerData = useRef<Customer[]>(filteredCustomers);

  const mergeSortedAndFilteredCustomers = useCallback(() => {
    for (let i = 0; i < dataSorted.length; i++) {
      const foundCustomer = filteredCustomers.find(
        (f) => f.customer_number === dataSorted[i].customer_id
      );

      const index = filteredCustomers.findIndex(
        (f) => f.customer_number === dataSorted[i].customer_id
      );

      filteredCustomers.splice(index, 1);

      if (foundCustomer) {
        filteredCustomers.unshift(foundCustomer);
      }
    }

    return filteredCustomers;
  }, [dataSorted, filteredCustomers]);

  if (handedDocs) {
    customerData.current = mergeSortedAndFilteredCustomers();
  }

  const searchInputOnSearch = (newValue?: string) => {
    setCustomerSearchTerm(newValue?.trim() || "");
  };

  const filtersPropsList = {
    selectedActivityFilterFrequencies,
    setActivityFilterFrequencies,
    selectedDate,
    setSelectedDate,
    selectedActivityFilterServiceTypes,
    setActivityFilterServiceTypes,
    selectedActivityFilterDeadline,
    setActivityFilterDeadline,
    selectedActivityFilterDefinitions,
    setActivityFilterDefinitions,
    selectedActivityFilterStatuses,
    setActivityFilterStatuses,
    selectedActivityFilterUserIds,
    setActivityFilterUserIds,

    selectedNextActivityFilterUsers,
    setNextActivityFilterUsers,

    overdueFilterEnabled,
    setEnabledOverdueFilter,
    resetTimeWindowComplete,
    setTimeWindowAt,

    handedDocs,
    setHandedDocs,
    disableSort,
    setDisableSort,

    deadlineOptions,
    frequencyOptions,
    serviceTypesOptions,
    statusOptions,
    activityTypeOptions,
  };

  const renderOption: JSX.Element = (
    <Checkbox
      className="mr-sm align-items-center"
      checked={handedDocs}
      onChange={() => setHandedDocs(!handedDocs)}
    />
  );

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <div className="d-flex  align-items-center justify-content-between">
            <div className="pr-md">
              <h1>{translate("MY_ASSIGNMENTS")}</h1>
            </div>
            <div className="vertical-divider pr-sm" />
            <label className="pr-sm">{translate("VIEW")}:</label>
            <TabList
              className="p-0"
              selectedValue={selectedTabView}
              onTabSelect={(_, item) =>
                item &&
                item.value &&
                (setIncompleteFilterEnabled(
                  item.value === "active" || item.value === "collapsed"
                ),
                setSelectedTabView(item.value as string))
              }
            >
              <Tab value="all" key="all">
                {translate("STANDARD_VIEW")}
              </Tab>
              <Tab value="active" key="active">
                {translate("ACTIVE_VIEW")}
              </Tab>
              <Tab value="collapsed" key="collapsed">
                {translate("COLLAPSED_VIEW")}
              </Tab>
            </TabList>
          </div>
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          <div className="d-flex flex-wrap fg-lg align-items-center">
            <div className=" d-flex flex-row justify-content-center align-items-center">
              <FilterSortList {...filtersPropsList} />
            </div>
            <button
              type="button"
              className={`filter-button px-xs d-flex align-items-center ${
                showAdditionalFilters ? "active-state-bg" : ""
              }`}
              onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}
            >
              <Filter20Regular className="mr-xs" />
              <span className="fw-600 px-xs">{translate("FILTER")}</span>
            </button>

            <ContextualMenuV9
              buttonText={translate("SORT")}
              buttonStyle="sort-button ghost-button min-width-fit-content"
              buttonIcon={() =>
                renderIcon(<ArrowSort20Regular className="pb-xxs mr-sm" />)
              }
              menuItems={[
                {
                  disabled: disableSort,
                  content: !disableSort ? renderOption : undefined,
                  persistOnClick: true,
                  text: translate("SORT_BY_DATE"),
                  tooltip: {
                    content: "SORT_UNAVAILABLE",
                    enabled: disableSort,
                  },
                },
              ]}
            />

            <div className="vw-15">
              <SearchInput
                placeholder={translate("SEARCH_PLACEHOLDER")}
                onChange={(_, newValue) => searchInputOnSearch(newValue.value)}
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    searchCustomers();
                  }
                }}
                data-testid="search-input"
                disabled={isSearchDataLoading}
              />
            </div>
          </div>
        </LayoutHeaderRight>
      </LayoutHeader>

      {showAdditionalFilters && (
        <LayoutSubHeader>
          <FilterSortBox {...filtersPropsList} />
        </LayoutSubHeader>
      )}

      <LayoutWrapper>
        {isSearchDataLoading ? (
          <Spinner
            label={translate("LOADING_CUSTOMERS")}
            className="p-3"
            size={SpinnerSize.ExtraSmall}
          />
        ) : !customers.isLoading && allCustomers.length === 0 ? (
          <p>{translate("NO_SEARCH_CUSTOMER_RESULTS")}</p>
        ) : (
          <>
            <CustomersServicesTable
              isFiltering={isFiltering}
              filteredCustomers={
                customerData.current.length > 0 && handedDocs
                  ? customerData.current
                  : filteredCustomers
              }
              serviceFilter={servicesFilter}
              openCustomerPanel={openCustomerPanel}
              incompleteFilterEnabled={incompleteFilterEnabled}
              isCollapsedView={selectedTabView === "collapsed"}
            />

            {(activities.isLoading ||
              customers.isLoading ||
              isSearchDataLoading) && (
              <Spinner
                label={translate("LOADING_ACTIVITIES")}
                className="p-3"
                size={SpinnerSize.ExtraSmall}
              />
            )}
          </>
        )}
        {selectedCustomer && (
          <CustomerDetailsPanel
            showCustomerDetailsPanel={showCustomerDetailsPanel}
            selectedCustomer={selectedCustomer}
            onDismiss={() => setShowCustomerDetailsPanel(false)}
          />
        )}
      </LayoutWrapper>
    </>
  );
}
