/* eslint-disable camelcase */
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startOfMonth } from "date-fns";
import { ArrowSort20Regular, Filter20Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import { useStickyState, useStickyStateForDate, useTranslation } from "hooks";
import {
  fetchServiceTypes,
  setTimelineWindow2,
  setTimelineWindowFromLocalStorage2,
  setActivitiesTimelineWindow2,
  getActivitiesTeam,
  fetchUnfinishedActivities,
  getOnedriveShortcutFolder,
  fetchActivitiesForCustomersV2,
} from "state/activities/actions";
import {
  isCustomerWithRestrictedAccess,
  isUserTeamOwner,
} from "libs/customer-settings-helpers";
import { AppDispatch } from "state/use-app-redux";
import { RootState } from "state";
import {
  fetchCustomersV2,
  fetchSearchCustomersV2,
} from "state/customers/actions";
import { renderIcon } from "libs/render-icon";
import ContextualMenuV9 from "components/contextualMenu/ContextualMenuV9";
import { DropdownV9, OptionItem } from "components/dropdown/DropdownV9";
import {
  ActivityDefinition,
  ActivityStatus,
  Recurrence,
} from "models/activities/activity";
import { UserSnapshot } from "models/user";
import { Customer } from "models/customer";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
  LayoutSubHeader,
  LayoutWrapper,
} from "components/layout/Layout";
import Checkbox from "components/checkbox";
import SearchInput from "components/input/search";
import { useCustomerServiceFilter } from "views/activities/components/filters/service-filters";
import { useCustomerDocumentsCompleted } from "views/activities/components/sorting/use-completed-documents";
import { PersonPicker } from "components/people";
import { CustomerSearchByEnum } from "api/customers";
import { CustomerDetailsPanel } from "../CustomerDetailsPanel";
import { FilterSortBoxV2, FilterSortListV2 } from "./FilterSortBoxV2";
import ServicesTable from "./ServicesTable";
import * as ServiceHelpers from "./helpers";
import { AssignmentManagementContextProvider } from "./AssignmentManagementContext";
import "./AssignmentManagementV2.scss";

export default function AssignmentManagementV2() {
  const { translate, language } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const { data: customers, isLoading: isCustomersLoading } = useSelector(
    (state: RootState) => state.customers
  );
  const {
    data: activitiesData,
    isLoading: isActivitiesLoading,
    hasLoaded: hasLoadedActivities,
    timelineWindow2,
    activitiesTeams: {
      data: activitiesTeams,
      isLoading: isActivitiesTeamsLoading,
    },
    unfinishedActivities: {
      data: unfinishedActivities,
      isLoading: isUnfinishedActivitiesLoading,
    },
    definitions: { data: definitions, dataMap: definitionsMap },
  } = useSelector((state: RootState) => state.activities);

  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
  const [customerSearchTerm, setCustomerSearchTerm] = useState("");
  const [searchByAssignee, setSearchByAssignee] = useState("");
  const [disableSort, setDisableSort] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const { currentUser } = useSelector((state: RootState) => state.users);
  const [showCustomerDetailsPanel, setShowCustomerDetailsPanel] =
    useState(false);
  const [isSearchResult, setIsSearchResult] = useState(false);

  const [selectedTabView, setSelectedTabView] = useStickyState<string>(
    ServiceHelpers.SELECTED_TAB_VIEW,
    ServiceHelpers.TabViewValueEnum.ALL
  );
  const [selectedActivityFilterFrequencies, setActivityFilterFrequencies] =
    useStickyState<Recurrence[]>(
      ServiceHelpers.FILTER_ACTIVITY_FREQUENCIES,
      []
    );
  const [selectedDate, setSelectedDate] = useStickyStateForDate(
    ServiceHelpers.FILTER_SELECTED_DATE
  );
  const [selectedActivityFilterServiceTypes, setActivityFilterServiceTypes] =
    useStickyState<string[]>(ServiceHelpers.FILTER_ACTIVITY_SERVICE_TYPES, []);
  const [selectedActivityFilterDeadline, setActivityFilterDeadline] =
    useStickyState<string | null>(
      ServiceHelpers.FILTER_ACTIVITY_DEADLINE,
      null
    );
  const [selectedActivityFilterDefinitions, setActivityFilterDefinitions] =
    useStickyState<ActivityDefinition[]>(
      ServiceHelpers.FILTER_ACTIVITY_DEFINITIONS,
      []
    );
  const [selectedActivityFilterStatuses, setActivityFilterStatuses] =
    useStickyState<ActivityStatus[]>(
      ServiceHelpers.FILTER_ACTIVITY_STATUSES,
      []
    );
  const [selectedActivityFilterUserIds, setActivityFilterUserIds] =
    useStickyState<UserSnapshot[]>(
      ServiceHelpers.FILTER_ACTIVITY_USERS_IDS,
      []
    );
  const [selectedNextActivityFilterUsers, setNextActivityFilterUsers] =
    useStickyState<UserSnapshot[]>(
      ServiceHelpers.FILTER_NEXT_ACTIVITY_USERS,
      []
    );
  const [overdueFilterEnabled, setEnabledOverdueFilter] =
    useStickyState<boolean>(ServiceHelpers.FILTER_OVERDUE_ENABLED, false);
  const [handedDocs, setHandedDocs] = useStickyState(
    ServiceHelpers.SORT_HANDED_DOCS,
    false
  );
  const [incompleteFilterEnabled, setIncompleteFilterEnabled] =
    useStickyState<boolean>(ServiceHelpers.FILTER_INCOMPLETE_ENABLED, false);
  const [searchBy, setSearchBy] = useStickyState<string>(
    ServiceHelpers.SEARCH_BY,
    ServiceHelpers.SearchByEnum.MyCustomers
  );

  const resetTimeWindowComplete = () => {
    if (selectedDate) {
      setSelectedDate(undefined);
      dispatch(setActivitiesTimelineWindow2({}));
    }
  };

  const setTimeWindowAt = (date: Date | null | undefined) => {
    const newDate = date ? startOfMonth(date) : undefined;
    setSelectedDate(newDate);

    if (newDate != null) {
      dispatch(setActivitiesTimelineWindow2({ months: [newDate] }));
    }
  };

  useEffect(() => {
    if (customers.length > 0) {
      const customerNumbers = customers.map(
        (customerState) => customerState.customer.customer_number
      );

      const shouldFetchActivities = [
        {
          condition: activitiesData.length === 0 && !isActivitiesLoading,
          action: fetchActivitiesForCustomersV2,
        },
        {
          condition: activitiesTeams.length === 0 && !isActivitiesTeamsLoading,
          action: getActivitiesTeam,
        },
        {
          condition:
            unfinishedActivities.length === 0 && !isUnfinishedActivitiesLoading,
          action: fetchUnfinishedActivities,
        },
      ];

      shouldFetchActivities.forEach(({ condition, action }) => {
        if (condition) {
          dispatch(action(customerNumbers));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  useEffect(() => {
    dispatch(getOnedriveShortcutFolder());
  }, [dispatch]);

  useEffect(() => {
    // TODO: reset store if other version of CB was loaded first
    // should be removed after going live with CB2
    if (
      !localStorage.getItem("conveyorBeltVersion") ||
      localStorage.getItem("conveyorBeltVersion") === "1"
    ) {
      localStorage.setItem("conveyorBeltVersion", "2");
      dispatch(setActivitiesTimelineWindow2({}));
    }
  }, [dispatch]);

  useEffect(() => {
    if (selectedDate) {
      const months = [selectedDate];
      dispatch(setTimelineWindow2(months));
      dispatch(setActivitiesTimelineWindow2({ months }));
    } else if (activitiesData.length === 0 && !hasLoadedActivities) {
      dispatch(setTimelineWindowFromLocalStorage2());
    }
    dispatch(fetchServiceTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, dispatch]);

  const deadlineOptions = ServiceHelpers.generateDeadlineOptions(translate, 5);

  const frequencyOptions = ServiceHelpers.RecurrenceTypes.filter(
    (recurrence) => recurrence !== Recurrence.Unknown
  ).map((recurrence) => ({
    value: recurrence,
    text: translate(recurrence),
  }));

  const serviceTypesOptions = ServiceHelpers.ServiceTypesList.map(
    (service) => ({
      value: service,
      text: translate(service),
    })
  );

  const statusOptions = [
    {
      value: ServiceHelpers.ACTIVITY_STATUS_PASSED_DEADLINE_KEY,
      text: translate("PASSED_DEADLINE"),
    },
    {
      value: ActivityStatus.ManagerSupport,
      text: translate(ActivityStatus.ManagerSupport),
    },
    {
      value: ActivityStatus.SupportSentToClient,
      text: translate(ActivityStatus.SupportSentToClient),
    },
    {
      value: ActivityStatus.SupportNeeded,
      text: translate(ActivityStatus.SupportNeeded),
    },
    {
      value: ActivityStatus.SupportFinished,
      text: translate(ActivityStatus.SupportFinished),
    },
  ];

  const activityTypeOptions = definitions
    .filter(
      (definition, index, array) =>
        array.findIndex(
          (def) => def.activity_name === definition.activity_name
        ) === index
    )
    .map((definition) => ({
      text: definition.getTranslation(language),
      value: definition.title,
      data: definition,
    }))
    .sort((a, b) => (a.text < b.text ? -1 : 1));

  const searchCustomers = useCallback(() => {
    dispatch(
      fetchSearchCustomersV2(customerSearchTerm, CustomerSearchByEnum.CUSTOMER)
    ).then(() => setIsSearchResult(true));
  }, [customerSearchTerm, dispatch]);

  useEffect(() => {
    if (searchByAssignee) {
      dispatch(
        fetchSearchCustomersV2(searchByAssignee, CustomerSearchByEnum.ASSIGNEE)
      ).then(() => setIsSearchResult(true));
    }
  }, [dispatch, searchByAssignee]);

  useEffect(() => {
    if (
      Boolean(selectedDate) &&
      selectedActivityFilterServiceTypes.length > 0
    ) {
      setDisableSort(false);
    } else {
      setDisableSort(true);
    }
  }, [selectedDate, selectedActivityFilterServiceTypes]);

  const { servicesFilter, isFiltering } = useCustomerServiceFilter({
    filterOverdue: overdueFilterEnabled,
    filterActive: incompleteFilterEnabled,
    filterStatuses: selectedActivityFilterStatuses,
    filterRecurrence: selectedActivityFilterFrequencies,
    filterDefinitions: selectedActivityFilterDefinitions,
    filterUserIds: selectedActivityFilterUserIds,
    filterServiceTypes: selectedActivityFilterServiceTypes,
    filterByMonth: selectedDate,
    filterByDeadline: selectedActivityFilterDeadline,
    filterNextUserIds: selectedNextActivityFilterUsers,
    definitionsMap,
  });

  const filterCustomer = (customer: Customer) => {
    if (
      isSearchResult &&
      isCustomerWithRestrictedAccess(customer) &&
      !isUserTeamOwner(customer, currentUser)
    ) {
      return false;
    }

    if (
      !customerSearchTerm ||
      searchBy !== ServiceHelpers.SearchByEnum.MyCustomers
    ) {
      return true;
    }

    const searchTerm = customerSearchTerm.toLowerCase();
    return (
      customer.salesperson_email.toLowerCase().includes(searchTerm) ||
      customer.customer_number.includes(customerSearchTerm) ||
      customer.name.toLowerCase().includes(searchTerm) ||
      customer
        .getManagers()
        .some((manager) => manager.email?.toLowerCase().includes(searchTerm))
    );
  };

  const allCustomers = customers.map(
    (customersState) => customersState.customer
  );
  const filteredCustomers = allCustomers
    .filter(filterCustomer)
    .sort((a, b) => a.name.localeCompare(b.name));

  const allFilteredCustomersServices = filteredCustomers.flatMap(
    ({ customer_number }) => {
      return {
        customer_number,
        servicesFilter: servicesFilter(customer_number),
      };
    }
  );

  const sortedCustomerActivities = useCustomerDocumentsCompleted(
    timelineWindow2,
    allFilteredCustomersServices
  );

  const filteredCustomerData = useRef<Customer[]>(filteredCustomers);

  const mergeSortedAndFilteredCustomers = useCallback(() => {
    for (let i = 0; i < sortedCustomerActivities.length; i++) {
      const foundCustomer = filteredCustomers.find(
        (f) => f.customer_number === sortedCustomerActivities[i].customer_id
      );

      const index = filteredCustomers.findIndex(
        ({ customer_number }) =>
          customer_number === sortedCustomerActivities[i].customer_id
      );

      filteredCustomers.splice(index, 1);

      if (foundCustomer) {
        filteredCustomers.unshift(foundCustomer);
      }
    }

    return filteredCustomers;
  }, [filteredCustomers, sortedCustomerActivities]);

  if (handedDocs) {
    filteredCustomerData.current = mergeSortedAndFilteredCustomers();
  }

  const openCustomerPanel = (customer: Customer) => {
    if (showCustomerDetailsPanel) {
      if (customer.customer_number === selectedCustomer) {
        setShowCustomerDetailsPanel(false);
        setSelectedCustomer("");
      } else {
        setSelectedCustomer(customer.customer_number);
      }
    } else {
      setShowCustomerDetailsPanel(true);
      setSelectedCustomer(customer.customer_number);
    }
  };

  const filtersPropsList = {
    selectedActivityFilterFrequencies,
    setActivityFilterFrequencies,
    selectedDate,
    setSelectedDate,
    selectedActivityFilterServiceTypes,
    setActivityFilterServiceTypes,
    selectedActivityFilterDeadline,
    setActivityFilterDeadline,
    selectedActivityFilterDefinitions,
    setActivityFilterDefinitions,
    selectedActivityFilterStatuses,
    setActivityFilterStatuses,
    selectedActivityFilterUserIds,
    setActivityFilterUserIds,

    selectedNextActivityFilterUsers,
    setNextActivityFilterUsers,

    overdueFilterEnabled,
    setEnabledOverdueFilter,
    resetTimeWindowComplete,
    setTimeWindowAt,

    handedDocs,
    setHandedDocs,
    disableSort,
    setDisableSort,

    deadlineOptions,
    frequencyOptions,
    serviceTypesOptions,
    statusOptions,
    activityTypeOptions,
  };

  const getDefaultValueForView = (tabView: string) => {
    switch (tabView) {
      case ServiceHelpers.TabViewValueEnum.ACTIVE:
        return translate("ACTIVE_VIEW");
      case ServiceHelpers.TabViewValueEnum.COLLAPSED:
        return translate("COLLAPSED_VIEW");

      default:
        return translate("STANDARD_VIEW");
    }
  };

  const tabViewOptions: OptionItem[] = [
    {
      value: ServiceHelpers.TabViewValueEnum.ALL,
      text: getDefaultValueForView(ServiceHelpers.TabViewValueEnum.ALL),
    },
    {
      value: ServiceHelpers.TabViewValueEnum.ACTIVE,
      text: getDefaultValueForView(ServiceHelpers.TabViewValueEnum.ACTIVE),
    },
    {
      value: ServiceHelpers.TabViewValueEnum.COLLAPSED,
      text: getDefaultValueForView(ServiceHelpers.TabViewValueEnum.COLLAPSED),
    },
  ];

  const getDefaultValueForSearchBy = (by: string) => {
    switch (by) {
      case ServiceHelpers.SearchByEnum.MyCustomers:
        return translate("MY_CUSTOMERS");
      case ServiceHelpers.SearchByEnum.AllCustomers:
        return translate("ALL_CUSTOMERS");

      default:
        return translate("ASSIGNEE");
    }
  };

  const searchByOptions: OptionItem[] = [
    {
      value: ServiceHelpers.SearchByEnum.MyCustomers,
      text: getDefaultValueForSearchBy(ServiceHelpers.SearchByEnum.MyCustomers),
    },
    {
      value: ServiceHelpers.SearchByEnum.AllCustomers,
      text: getDefaultValueForSearchBy(
        ServiceHelpers.SearchByEnum.AllCustomers
      ),
    },
    // TODO: Commented for now, until data issues are resolved
    // {
    //   value: ServiceHelpers.SearchByEnum.Employee,
    //   text: getDefaultValueForSearchBy(ServiceHelpers.SearchByEnum.Employee),
    // },
  ];

  const handleSearchBySelect = (optionValue?: string) => {
    if (optionValue === undefined) {
      return;
    }

    setSearchBy(optionValue);
    setCustomerSearchTerm("");
    setSearchByAssignee("");

    if (isSearchResult) {
      fetchMyCustomers();
    }
  };

  const handleOnSearchInputChange = (
    nativeEvent: MouseEvent | Event,
    value: string
  ) => {
    setCustomerSearchTerm(value);

    if (
      searchBy === ServiceHelpers.SearchByEnum.AllCustomers &&
      nativeEvent instanceof PointerEvent &&
      nativeEvent.pointerType === "mouse" &&
      nativeEvent.type === "click" &&
      value === "" &&
      isSearchResult
    ) {
      fetchMyCustomers();
    }
  };

  const setSearchToMyCustomers = () => {
    setSearchBy(ServiceHelpers.SearchByEnum.MyCustomers);
    setCustomerSearchTerm("");
    fetchMyCustomers();
  };

  const fetchMyCustomers = () => {
    dispatch(fetchCustomersV2()).then(() => setIsSearchResult(false));
  };

  return (
    <AssignmentManagementContextProvider
      servicesFilter={servicesFilter}
      isFiltering={isFiltering}
      setSearchToMyCustomers={setSearchToMyCustomers}
    >
      <LayoutHeader scrollable>
        <LayoutHeaderLeft className="mr-lg">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pr-md">
              <h1>{translate("MY_ASSIGNMENTS")}</h1>
            </div>
            <div className="vertical-divider pr-sm" />
            <label className="pr-sm">{translate("VIEW")}:</label>
            <DropdownV9
              className="dropdown-view width-max"
              appearance="underline"
              selectedOptions={[selectedTabView]}
              defaultValue={getDefaultValueForView(selectedTabView)}
              onOptionSelect={(_, { optionValue }) => {
                if (optionValue) {
                  setIncompleteFilterEnabled(
                    optionValue === ServiceHelpers.TabViewValueEnum.ACTIVE ||
                      optionValue === ServiceHelpers.TabViewValueEnum.COLLAPSED
                  );
                  setSelectedTabView(optionValue);
                }
              }}
              options={tabViewOptions}
            />
          </div>
        </LayoutHeaderLeft>

        <LayoutHeaderRight className="d-flex flex-none">
          <div className="d-flex flex-wrap fg-sm align-items-center">
            <div className="d-flex flex-row justify-content-center">
              <div className="d-flex flex-row justify-content-center align-items-center mr-sm">
                <FilterSortListV2 {...filtersPropsList} />
              </div>
              <Button
                appearance="transparent"
                className={showAdditionalFilters ? "active-state-bg" : ""}
                icon={<Filter20Regular />}
                onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}
              >
                <span className="fw-600 px-xs">{translate("FILTER")}</span>
              </Button>
              <ContextualMenuV9
                buttonText={translate("SORT")}
                buttonIcon={() =>
                  renderIcon(<ArrowSort20Regular className="pb-xxs mr-sm" />)
                }
                menuItems={[
                  {
                    disabled: disableSort,
                    content: disableSort ? undefined : (
                      <Checkbox
                        className="mr-sm align-items-center"
                        checked={handedDocs}
                        onChange={() => setHandedDocs(!handedDocs)}
                        noMargin={false}
                      />
                    ),
                    persistOnClick: true,
                    text: translate("SORT_BY_DATE"),
                    tooltip: disableSort
                      ? { content: "SORT_UNAVAILABLE" }
                      : undefined,
                  },
                ]}
              />
            </div>

            <div className="reload-vertical-divider" />

            <div className="d-flex flex-row justify-content-center align-items-center">
              <DropdownV9
                className="dropdown-view search-by-dropdown"
                appearance="underline"
                label={translate("SEARCH_BY")}
                disabled={isCustomersLoading}
                options={searchByOptions}
                selectedOptions={[searchBy]}
                defaultValue={getDefaultValueForView(selectedTabView)}
                onOptionSelect={(_, { optionValue }) =>
                  handleSearchBySelect(optionValue)
                }
              />

              <div className="vw-15 ml-sm">
                {searchBy === ServiceHelpers.SearchByEnum.Employee ? (
                  <PersonPicker
                    className={searchByAssignee ? "" : `empty-person-picker-bg`}
                    selectionMode="single"
                    selectedUsers={
                      searchByAssignee ? [{ id: searchByAssignee }] : []
                    }
                    onPersonChange={(_, __, person) =>
                      setSearchByAssignee(person?.userPrincipalName || "")
                    }
                    removeSelectedUser={() => {
                      setSearchByAssignee("");
                      fetchMyCustomers();
                    }}
                    disabled={isCustomersLoading}
                  />
                ) : (
                  <SearchInput
                    className="w-100"
                    placeholder={translate("SEARCH_PLACEHOLDER")}
                    value={customerSearchTerm}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        searchBy === ServiceHelpers.SearchByEnum.AllCustomers
                      ) {
                        searchCustomers();
                      }
                    }}
                    onChange={({ nativeEvent }, { value }) =>
                      handleOnSearchInputChange(nativeEvent, value)
                    }
                    data-testid="search-input"
                    disabled={isCustomersLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </LayoutHeaderRight>
      </LayoutHeader>
      {showAdditionalFilters && (
        <LayoutSubHeader>
          <FilterSortBoxV2 {...filtersPropsList} />
        </LayoutSubHeader>
      )}
      <LayoutWrapper>
        {!isCustomersLoading && allCustomers.length === 0 ? (
          <p>{translate("NO_SEARCH_CUSTOMER_RESULTS")}</p>
        ) : (
          <ServicesTable
            customers={
              filteredCustomerData.current.length > 0 && handedDocs
                ? filteredCustomerData.current
                : filteredCustomers
            }
            selectedActivityFilterUsers={selectedActivityFilterUserIds}
            isTimelineLoading={isActivitiesLoading}
            isFullLoading={isCustomersLoading}
            isCollapsedView={
              selectedTabView === ServiceHelpers.TabViewValueEnum.COLLAPSED
            }
            incompleteFilterEnabled={incompleteFilterEnabled}
            openCustomerPanel={openCustomerPanel}
          />
        )}
      </LayoutWrapper>
      {selectedCustomer && (
        <CustomerDetailsPanel
          showCustomerDetailsPanel={showCustomerDetailsPanel}
          selectedCustomer={selectedCustomer}
          onDismiss={() => setShowCustomerDetailsPanel(false)}
        />
      )}
    </AssignmentManagementContextProvider>
  );
}
