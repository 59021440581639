import { useState } from "react";
import { useSelector } from "react-redux";
import { DrawerBody, OverlayDrawer } from "@fluentui/react-components";

import { useTranslation } from "hooks";
import { formatDate } from "libs/date/date-format";
import { numberAsSwedishCurrency } from "libs/number-format";
import { PotentialDealItem } from "models/deals/deal";
import { PotentialDealsGrid } from "shared/potentialDealsGrid/PotentialDealsGrid";
import { RootState } from "state";
import DrawerHeader from "components/drawer/drawerHeader/DrawerHeader";

interface IPotentialDealsProps {
  isInPanelDisplay?: boolean;
}

function PotentialDeals({ isInPanelDisplay = false }: IPotentialDealsProps) {
  const { translate } = useTranslation();

  const [showPanel, setShowPanel] = useState(isInPanelDisplay);

  const {
    dealsByOrgNumber: { data: potentialDeals },
    dealOptions: { data: dealOptions },
    pipelines: { data: pipelines },
    products: { data: products },
  } = useSelector((state: RootState) => state.sales);

  const dealSources = dealOptions.find((d) => d.name === "deal_source");

  const items: PotentialDealItem[] = potentialDeals.map((deal) => {
    return {
      dealId: deal.id,
      // eslint-disable-next-line no-underscore-dangle
      businessArea: deal.affarsomrade_deal_,
      dateCreatedFormatted: formatDate(deal.createdAt),
      dateCreatedAsMilliseconds: new Date(deal.createdAt).getTime(),
      sourceValue: parseInt(deal.deal_source, 10),
      sourceLabel:
        dealSources?.options.find((o) => o.value === deal.deal_source)?.label ||
        "",
      services: products
        .filter((product) => deal.productIds?.includes(product.id))
        .map((product) => product.name),
      businessValueAmount: deal.amount,
      businessValueAmountFormatted:
        deal.amount !== undefined
          ? numberAsSwedishCurrency(deal.amount, true)
          : "",
      contactPersonName: deal.contact
        ? `${deal.contact.firstname} ${deal.contact.lastname}`
        : "",
      stage:
        pipelines
          .find((pipeline) => pipeline.id === deal.pipeline)
          ?.stages.find((stage) => stage.id === deal.dealstage)?.label || "",
      responsiblePersonEmail: deal.deal_assignee_email || "",
    };
  });

  return (
    <div>
      <div className="stack-horizontal">
        <PotentialDealsGrid potentialDeals={potentialDeals} items={items} />
        {potentialDeals.length > 1 && (
          <div
            className="cursor-pointer pog-link mt-xl ml-lg"
            onClick={() => {
              setShowPanel(!showPanel);
            }}
          >
            <span className="blue-link-underline text-wrap-none fw-semibold counter-number pl-md">
              {translate("SHOW_ALL")}
            </span>
          </div>
        )}
      </div>
      <OverlayDrawer open={showPanel} position="end" size="large">
        <DrawerHeader
          onDismiss={() => {
            setShowPanel(false);
          }}
          header="BUSINESS_OPPORTUNITIES"
        />
        <DrawerBody>
          <PotentialDealsGrid
            potentialDeals={potentialDeals}
            items={items}
            isExpandedView
          />
        </DrawerBody>
      </OverlayDrawer>
    </div>
  );
}

export default PotentialDeals;
